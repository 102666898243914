import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components/web
import ListView from "components/web/view/list";
import CalendarView from "components/web/suggestHour";
import Button from "components/web/button";

//images
import Close from "assets/images/menu/close.png";
import Arrow from "assets/images/menu/arrowBack.svg";
// srcTopBar: Arrow,

//pages
import SugestHour from "./sugestHour";
import DetailOrder from "./detailOrder";
import ServiceAdd from "./serviceAdd";

import Theme from "theme";

export default () => {
  const { PageInfo, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  //sugesstTime
  const [sugestHourShow, setSugestHourShow] = useState(false);

  //detalhes
  const [details, setDetails] = useState(false);

  //serviceAdd
  const [serviceAddShow, setServiceAddShow] = useState(false);

  //horários
  const [selectedHours, setSelectedHours] = useState([]);

  //pedido
  const [order, setOrder] = useState({});

  //estado do select
  const [selectState, setSelectState] = useState(-1);

  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "orders" ? "flex" : "none",
    },
    children: [
      SugestHour(
        sugestHourShow,
        setSugestHourShow,
        order,
        selectedHours,
        setSelectedHours
      ),
      //list
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          ListView({
            id: "orders",
            title: "Lista de pedidos",
            //filtros
            filter: [
              //novos pedidos
              {
                id: "NewOrder",
                type: "button",
                label: "Novos pedidos",
                query: "new",
                // count: ,
                style: {
                  box: {},
                },
              },
              //pedidos em andamento
              {
                id: "OrderProgress",
                type: "button",
                label: "Em andamento",
                query: "waiting",
                style: {
                  box: {},
                },
              },
              //pedidos finalizados
              {
                id: "OrderFinish",
                type: "button",
                label: "Finalizados",
                query: "finish",
                style: {
                  box: {},
                },
              },
            ],
            style: {
              cel: (props) => {
                switch (props.col) {
                  case "status":
                    return {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                      borderRadius: 4,
                      padding: 2,
                      fontSize: Theme.fontSizes.row,
                      fontWeight: "600",
                      color:
                        props.item[props.col].code === 0
                          ? "rgba(229, 46, 46, 1)"
                          : props.item[props.col].code === 1
                          ? "rgba(5, 150, 105, 1)"
                          : props.item[props.col].code >= 2
                          ? "rgba(75, 85, 99, 1)"
                          : "rgba(75, 85, 99, 1)",
                      backgroundColor:
                        props.item[props.col].code === 0
                          ? "rgba(229, 46, 46, 0.5)"
                          : props.item[props.col].code === 1
                          ? "rgba(167, 243, 208, 1)"
                          : props.item[props.col].code >= 2
                          ? "rgba(75, 85, 99, 0.5)"
                          : "rgba(75, 85, 99, 0.5)",
                    };
                  default:
                    return {
                      fontWeight: "normal",
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 42,
                maxHeight: 42,
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              id: {
                title: "ID",
                condition: (id) => {
                  return "# " + id.id;
                },
                style: {},
              },
              people: {
                title: "Cliente",
                key: ["people", "name"],
                style: {
                  flex: 1.5,
                },
              },
              pet: {
                key: ["pet", "name"],
                title: "Pet",
              },
              services: {
                title: "Serviço",
                style: {
                  flex: 1.5,
                },
                condition: (param) => {
                  const serviceNames = param.services.map(
                    (item) => item?.service?.category?.name
                  );
                  return serviceNames.length > 1
                    ? `${serviceNames[0].substring(0, 40)}...`
                    : serviceNames[0];
                },
              },
              order_value: {
                title: "Valor total",
                condition: (param) => {
                  const total =
                    (param?.delivery_service_value || 0) +
                    (param?.service_value || 0);
                  return (
                    "R$ " +
                    total.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  );
                },
                style: {},
              },
              service_date: {
                // key: [],
                title: "Agendamento",
                style: {
                  flex: 1.5,
                },
              },
              status: {
                title: "Status",
                style: {
                  flex: 1.8,
                },
                condition: (param) => {
                  return param.status.name;
                },
              },
              situation: {
                title: "Ações",
                item: [
                  {
                    value: ({ row }) => {
                      return {
                        component: "container",
                        style: {
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        children: [
                          {
                            render: (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <select
                                  value={selectState}
                                  onChange={async (item) => {
                                    //cancelar
                                    if (item.target.value === "1") {
                                      PopupShow({
                                        title:
                                          "Deseja cancelar " + row.id + "?",
                                        cancelText: "Não",
                                        confirmText: "Sim",
                                        style: {
                                          box: {
                                            width: "auto",
                                            height: "auto",
                                            alignItems: "center",
                                          },
                                          close: {
                                            display: "none",
                                          },
                                        },
                                        onConfirm: async () => {
                                          const result = await api.order.cancel(
                                            {
                                              order: row._id,
                                            }
                                          );
                                          setSelectState(-1);
                                          setUpdate("orders" + new Date());
                                          GoPage("orders");
                                        },
                                        onCancel: async () => {
                                          setSelectState(-1);
                                          setUpdate("orders" + new Date());
                                        },
                                        item: [],
                                      });
                                    }
                                    //Novo horário
                                    else if (item.target.value === "2") {
                                      setSugestHourShow(true);
                                      setOrder(row);
                                    }
                                    //confirmar
                                    else if (item.target.value === "3") {
                                      PopupShow({
                                        title:
                                          "Deseja confirmar " + row.id + " ?",
                                        cancelText: "Não",
                                        confirmText: "Sim",
                                        style: {
                                          box: {
                                            width: "auto",
                                            height: "auto",
                                            alignItems: "center",
                                          },
                                          close: {
                                            display: "none",
                                          },
                                        },
                                        onConfirm: async () => {
                                          const result =
                                            await api.order.confirm({
                                              order: row._id,
                                            });
                                          setSelectState(-1);
                                          setUpdate("orders" + new Date());
                                          GoPage("orders");
                                        },
                                        onCancel: async () => {
                                          setUpdate("orders" + new Date());
                                          setSelectState(-1);
                                        },
                                        item: [],
                                      });
                                      setUpdate("orders" + new Date());
                                      GoPage("orders");
                                    }
                                    //Finalizar serviço
                                    else if (item.target.value === "4") {
                                      PopupShow({
                                        title:
                                          "Deseja finalizar o serviço " +
                                          row.id +
                                          "?",
                                        style: {
                                          box: {
                                            width: "auto",
                                            height: "auto",
                                            alignItems: "center",
                                          },
                                          close: {
                                            display: "none",
                                          },
                                        },
                                        cancelText: "Não",
                                        confirmText: "Sim",
                                        onConfirm: async () => {
                                          await api.order.finalizeService({
                                            order: row._id,
                                          });
                                          setSelectState(-1);
                                          setUpdate("orders" + new Date());
                                          GoPage("orders");
                                        },
                                        onCancel: async () => {
                                          setSelectState(-1);
                                          setUpdate("orders" + new Date());
                                        },
                                        item: [],
                                      });
                                    }
                                    //O pet chegou
                                    else if (item.target.value === "5") {
                                      await api.order.petDelivered({
                                        order: row._id,
                                      });
                                      setSelectState(-1);
                                      setUpdate("orders" + new Date());
                                      GoPage("orders");
                                    }
                                    //Serviço adicional
                                    else if (item.target.value === "6") {
                                      setServiceAddShow(true);
                                      setOrder(row);
                                    }
                                    //O pet já foi
                                    else if (item.target.value === "7") {
                                      await api.order.finalizeOrder({
                                        order: row._id,
                                      });
                                      setUpdate("orders" + new Date());
                                      GoPage("orders");
                                    }
                                  }}
                                  style={{
                                    width: "auto",
                                    display:
                                      row.status.code === 997 ||
                                      row.status.code <= 1
                                        ? "none"
                                        : "flex",
                                    height: 26,
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    borderColor: "#FFBC11",
                                    backgroundColor: "#FFE6A7",
                                    borderRadius: 5,
                                  }}
                                >
                                  <option value={-1} disabled selected>
                                    -
                                  </option>
                                  {/* {row.status?.code === 998 &&
                                    [
                                      "Confirmar",
                                      "Cancelar",
                                      "Ajuste de horário",
                                    ].map((text, value) => {
                                      return (
                                        <option value={value + 1}>
                                          {text}
                                        </option>
                                      );
                                    })} */}

                                  {row.status?.code === 998 && (
                                    <>
                                      <option value={1}>Cancelar</option>
                                      <option value={2}>
                                        Sugerir novo horário
                                      </option>
                                      <option value={3}>Confirmar</option>
                                    </>
                                  )}

                                  {row.status?.code === 3 && (
                                    <option value={4}>Finalizar serviço</option>
                                  )}

                                  {row.status?.code === 4 && (
                                    <>
                                      <option value={5}>O pet chegou</option>
                                      <option value={6}>
                                        Serviço adicional
                                      </option>
                                    </>
                                  )}

                                  {row.status?.code === 5 && (
                                    <>
                                      <option value={5}>O pet chegou</option>
                                      <option value={6}>
                                        Serviço adicional
                                      </option>
                                    </>
                                  )}

                                  {row.status?.code === 2 && (
                                    <option value={7}>O pet já saiu</option>
                                  )}
                                </select>
                              </div>
                            ),
                          },
                        ],
                      };
                    },
                  },
                ],
              },
              action: {
                title: "Detalhes",
                item: [
                  {
                    value: ({ row }) => {
                      return {
                        component: "container",
                        style: {
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        children: [
                          {
                            component: "text",
                            text: "Abrir detalhes",
                            style: {
                              color: "blue",
                              fontWeight: "bold",
                              cursor: "pointer",
                            },
                            onClick: () => {
                              setDetails(true);
                              setOrder(row);
                            },
                          },
                        ],
                      };
                    },
                  },
                ],
              },
            },
            item: api.order.list,
          }),
        ],
      },
      DetailOrder(
        setSugestHourShow,
        details,
        setDetails,
        order,
        setOrder,
        setServiceAddShow
      ),
      ServiceAdd(serviceAddShow, setServiceAddShow, order, setDetails),
    ],
  };
};
