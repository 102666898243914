import { useEffect, useState } from "react";
import api from "service";
import { ShowPage } from "context/showPage";
import AddView from "components/web/view/add";
import { ShowPopup } from "context/popup";

import Button from "components/web/button";

import Theme from "theme";

import Arrow from "assets/images/menu/arrowBack.svg";
import input from "../../../components/web/input";

export default (props) => {
  const { PageInfo, GoPage, GoBack } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  //animalCategory
  const [animalsType, setAnimalsType] = useState([]);
  const [animalType, setAnimalType] = useState({});

  //categoria
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  //subCategory
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  //descrição
  const [description, setDescription] = useState("");

  //values
  const [valuePP, setValuePP] = useState(0);
  const [valueP, setValueP] = useState(0);
  const [valueM, setValueM] = useState(0);
  const [valueG, setValueG] = useState(0);
  const [valueGG, setValueGG] = useState(0);

  const inputStyle = {
    maxWidth: 256,
    minWidth: 256,
    item: {
      width: 256,
    },
    input: {
      maxWidth: 256,
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: 16,
      backgroundColor: "transparent",
    },
    title: {
      ...Theme.text.input.title,
    },
    label: {
      fontSize: 16,
    },
    select: {
      border: "1px solid #bfbfbf",
      maxWidth: 256,
      minWidth: 256,
      borderRadius: 4,
      padding: 4,
      fontSize: 16,
    },
    button: {
      padding: 12,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 4,
    },
  };

  useEffect(async () => {
    if (PageInfo.show === "services-list") {
      let resAnimalType = await api.utils.animal_category();
      setAnimalsType(resAnimalType?.data?.data || []);

      let { data } = await api.utils.service_category();

      let listCategory = [];
      data?.data?.map((cat) => {
        listCategory.push({
          _id: cat._id,
          name: cat.name,
          // value: cat,
          // icon: cat.image,
        });
      });
      setCategory(listCategory);
    }
  }, [PageInfo.show]);

  const handleSubmit = async () => {
    const formattedData =
      animalType?.name === "Gato"
        ? {
            animal_category: animalType?._id,
            category: selectedCategory?._id,
            sub_category: selectedSubCategory?._id,
            description: description,
            value: { 999: parseFloat(valuePP) || 0 },
          }
        : {
            animal_category: animalType?._id,
            category: selectedCategory?._id,
            sub_category: selectedSubCategory?._id,
            description: description,
            value: {
              1: parseFloat(valuePP) || 0,
              2: parseFloat(valueP) || 0,
              3: parseFloat(valueM) || 0,
              4: parseFloat(valueG) || 0,
              5: parseFloat(valueGG) || 0,
            },
          };
    if (
      selectedCategory?._id !== undefined &&
      selectedCategory?._id !== "none" &&
      selectedSubCategory?._id !== undefined &&
      selectedSubCategory?._id !== "none" &&
      animalType?.id !== undefined &&
      animalType?.id !== "none"
    ) {
      const valores = [valuePP, valueP, valueM, valueG, valueGG];
      if (valores.some((valor) => valor !== 0)) {
        try {
          const response = await api.provider.serviceAdd(formattedData);

          if (response?.data?.status === true) {
            PopupShow({
              description: "",
              style: {
                box: {
                  width: "auto",
                  alignItems: "center",
                },
                close: {
                  display: "none",
                },
              },
              item: [
                {
                  component: "container",
                  style: {
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 12,
                  },
                  children: [
                    {
                      component: "text",
                      text: "Serviço salvo com sucesso!",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 18,
                      },
                    },
                  ],
                },
              ],
              buttons: [
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    gap: 8,
                    marginTop: 12,
                  },
                  children: [
                    //btn fechar
                    Button({
                      label: "Voltar para lista",
                      style: {
                        button: {
                          backgroundColor: "#E4EEFF",
                          width: "100%",
                          alignItems: "center",
                          height: 42,
                          borderRadius: 6,
                          border: "none",
                        },
                        label: {
                          fontFamily: Theme.fontFamilies.primary,
                          fontSize: 18,
                          color: "#0864F1",
                        },
                      },
                      onPress: async () => {
                        setValuePP(0);
                        setValueP(0);
                        setValueM(0);
                        setValueG(0);
                        setValueGG(0);
                        setDescription("");
                        setSubCategory([]);
                        setSelectedCategory({});
                        setCategory([]);
                        setAnimalType("");
                        GoBack();
                        PopupClose();
                      },
                    }),
                  ],
                },
              ],
            });
          }
        } catch (error) {
          console.error("Erro ao enviar dados:", error.response?.data || error);
        }
      } else {
        PopupShow({
          description: "",
          style: {
            box: {
              width: "auto",
              alignItems: "center",
            },
            close: {
              display: "none",
            },
          },
          item: [
            {
              component: "container",
              style: {
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                gap: 12,
              },
              children: [
                {
                  component: "text",
                  text: "Adicone pelo menos um valor!",
                  style: {
                    fontFamily: "Poppins",
                    fontSize: 18,
                  },
                },
              ],
            },
          ],
          buttons: [
            {
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                gap: 8,
                marginTop: 12,
              },
              children: [
                //btn fechar
                Button({
                  label: "Fechar",
                  style: {
                    button: {
                      backgroundColor: "#FFB7B7",
                      width: "100%",
                      alignItems: "center",
                      height: 42,
                      borderRadius: 6,
                      border: "none",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                      color: "#DC2626",
                    },
                  },
                  onPress: async () => {
                    PopupClose();
                  },
                }),
              ],
            },
          ],
        });
      }
    } else {
      PopupShow({
        description: "",
        style: {
          box: {
            width: "auto",
            alignItems: "center",
          },
          close: {
            display: "none",
          },
        },
        item: [
          {
            component: "container",
            style: {
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              gap: 12,
            },
            children: [
              {
                component: "text",
                text:
                  animalType?.id === undefined || animalType?.id === "none"
                    ? "Selecione uma espécie!"
                    : "Selecione a categoria e serviço!",
                style: {
                  fontFamily: "Poppins",
                  fontSize: 18,
                },
              },
            ],
          },
        ],
        buttons: [
          {
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              gap: 8,
              marginTop: 12,
            },
            children: [
              //btn fechar
              Button({
                label: "Fechar",
                style: {
                  button: {
                    backgroundColor: "#FFB7B7",
                    width: "100%",
                    alignItems: "center",
                    height: 42,
                    borderRadius: 6,
                    border: "none",
                  },
                  label: {
                    fontFamily: Theme.fontFamilies.primary,
                    fontSize: 18,
                    color: "#DC2626",
                  },
                },
                onPress: async () => {
                  PopupClose();
                },
              }),
            ],
          },
        ],
      });
    }
  };

  //ServiceAdd
  return {
    component: "container",
    style: {
      display: PageInfo.show === "service-add" ? "flex" : "none",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100vh",
      flex: 1,
      zIndex: 1,
    },
    children: [
      AddView({
        direction: "column",
        title: "Criação de novo serviço",
        srcTopBar: Arrow,
        form: {
          onSave: handleSubmit,
          func: (data, info, clear, goBack) => {},
          style: {
            main: {
              flexWrap: "nowrap",
              overflowY: "auto",
              overflowX: "hidden",
              width: "50%",
              heigth: "100%",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              border: "1px solid #CBD5E1",
              borderRadius: 4,
              gap: 12,
              padding: "1%",
              marginBottom: 12,
            },
            box: {
              1: {},
            },
            btn: {
              cancel: {},
              save: {},
              style: {
                width: "50%",
              },
            },
          },
          box: {
            1: {
              style: {
                display: "flex",
                minWidth: "100%",
                flexDirection: "column",
                gap: 22,
                padding: 8,
              },
              input: [
                //Button animal categoory
                {
                  type: "div",
                  style: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: 12,
                    borderBottom: "1px solid #CBD5E1",
                  },
                  children: [
                    //text
                    {
                      type: "text",
                      text: "Esse serviço é para qual espécie ?",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 16,
                      },
                    },
                    //button
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        padding: 8,
                        gap: 8,
                        cursor: "pointer",
                        width: "auto",
                      },
                      children: [
                        ...animalsType?.map((pet) => ({
                          type: "div",
                          style: {
                            display: "flex",
                            maxHeigth: 64,
                            maxWidth: 300,
                            borderRadius: 4,
                            flexDirection: "row",
                            border:
                              pet?._id == animalType?._id
                                ? "1px solid #2EC4B6"
                                : "1px solid #D2D2D2",
                            background:
                              pet?._id == animalType._id
                                ? "rgba(46, 196, 182, 0.3)"
                                : "#F7F7FC",
                          },
                          children: [
                            {
                              type: "image",
                              src: pet?.image,
                              size: 32,
                              style: {
                                iconBox: {
                                  width: 32,
                                  heigth: 32,
                                },
                              },
                            },
                            {
                              type: "button",
                              label: pet?.name,
                              style: inputStyle,
                              onClick: () => {
                                setAnimalType(pet);
                              },
                            },
                          ],
                        })),
                      ],
                    },
                  ],
                },
                //Select category
                {
                  type: "div",
                  style: {
                    display: "flex",
                    maxHeigth: 64,
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: 12,
                    borderBottom: "1px solid #CBD5E1",
                  },
                  children: [
                    //text
                    {
                      type: "text",
                      text: "Selecione a categoria do serviço",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 16,
                      },
                    },
                    //select
                    {
                      type: "select",
                      style: inputStyle,
                      option: category,
                      update: category,
                      value: selectedCategory?._id,
                      func: async (cat) => {
                        setSelectedCategory(cat);
                        let categoryList =
                          await api.provider.getServicesOptionsSubcategory(
                            cat._id
                          );
                        let listSubCategory = [];
                        categoryList.data.data.map((scat) => {
                          listSubCategory.push({
                            _id: scat._id,
                            name: scat.name,
                            // value: scat,
                          });
                        });
                        setSubCategory(listSubCategory);
                      },
                    },
                  ],
                },
                //Select service category
                {
                  type: "div",
                  style: {
                    display: "flex",
                    maxHeigth: 64,
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: 12,
                    borderBottom: "1px solid #CBD5E1",
                  },
                  children: [
                    //text
                    {
                      type: "text",
                      text: "Selecione o nome do serviço",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 16,
                      },
                    },
                    //button
                    {
                      type: "select",
                      style: inputStyle,
                      option: subCategory,
                      update: subCategory,
                      value: selectedSubCategory?._id,
                      func: async (sub_cat) => {
                        setSelectedSubCategory(sub_cat);
                      },
                    },
                  ],
                },
              ],
            },
            2: {
              style: {
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: 8,
                padding: 8,
              },
              input: [
                //valor - (PP) ou gato
                {
                  type: "div",
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  },
                  children: [
                    //text
                    {
                      type: "text",
                      text:
                        animalType?.name === "Gato"
                          ? "Valor do serviço"
                          : "Valor - Até 5 kg (PP)",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 16,
                      },
                    },
                    //input
                    {
                      keyid: "value.1",
                      type: "inputCurrency",
                      placeHolder: "0,00",
                      style: {
                        ...inputStyle,
                        input: {
                          backgroundColor:
                            valuePP === 0 ? "#d3d3d3" : "transparent",
                          border: "none",
                        },
                        coin: { display: "none" },
                        box: {
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      },
                      value: valuePP,
                      set: (e, value) => {
                        let price = parseFloat(e.target.value);
                        if (price !== 0) {
                          setValuePP(value);
                        } else {
                          setValuePP(0);
                        }
                      },
                    },
                  ],
                },
                //demais inputs
                {
                  type: "div",
                  style: {
                    display: animalType?.name === "Gato" ? "none" : "flex",
                    maxWidth: "100%",
                    heigth: "auto",
                    gap: 8,
                  },
                  children: [
                    //valor - (P)
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                      },
                      children: [
                        //text
                        {
                          type: "text",
                          text: "Valor - 6 a 10 kg (P)",
                          style: {
                            fontFamily: "Poppins",
                            fontSize: 16,
                          },
                        },
                        //input
                        {
                          keyid: "value.2",
                          type: "inputCurrency",
                          placeHolder: "0,00",
                          style: {
                            ...inputStyle,
                            input: {
                              backgroundColor:
                                valueP === 0 ? "#d3d3d3" : "transparent",
                              border: "none",
                            },
                            coin: { display: "none" },
                            box: {
                              alignItems: "center",
                              justifyContent: "center",
                            },
                          },
                          value: valueP,
                          set: (e, value) => {
                            let price = parseFloat(e.target.value);
                            if (price !== 0) {
                              setValueP(value);
                            } else {
                              setValueP(0);
                            }
                          },
                        },
                      ],
                    },
                    //valor - (M)
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                      },
                      children: [
                        //text
                        {
                          type: "text",
                          text: "Valor - 11 a 20 kg (M)",
                          style: {
                            fontFamily: "Poppins",
                            fontSize: 16,
                          },
                        },
                        //input
                        {
                          keyid: "value.3",
                          type: "inputCurrency",
                          placeHolder: "0,00",
                          style: {
                            ...inputStyle,
                            input: {
                              backgroundColor:
                                valueM === 0 ? "#d3d3d3" : "transparent",
                              border: "none",
                            },
                            coin: { display: "none" },
                            box: {
                              alignItems: "center",
                              justifyContent: "center",
                            },
                          },
                          value: valueM,
                          set: (e, value) => {
                            let price = parseFloat(e.target.value);
                            if (price !== 0) {
                              setValueM(value);
                            } else {
                              setValueM(0);
                            }
                          },
                        },
                      ],
                    },
                    //valor - (G)
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                      },
                      children: [
                        //text
                        {
                          type: "text",
                          text: "Valor - 21 a 30 kg (M)",
                          style: {
                            fontFamily: "Poppins",
                            fontSize: 16,
                          },
                        },
                        //input
                        {
                          keyid: "value.4",
                          type: "inputCurrency",
                          placeHolder: "0,00",
                          style: {
                            ...inputStyle,
                            input: {
                              backgroundColor:
                                valueG === 0 ? "#d3d3d3" : "transparent",
                              border: "none",
                            },
                            coin: { display: "none" },
                            box: {
                              alignItems: "center",
                              justifyContent: "center",
                            },
                          },
                          value: valueG,
                          set: (e, value) => {
                            let price = parseFloat(e.target.value);
                            if (price !== 0) {
                              setValueG(value);
                            } else {
                              setValueG(0);
                            }
                          },
                        },
                      ],
                    },
                    //valor - (GG)
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                      },
                      children: [
                        //text
                        {
                          type: "text",
                          text: "Valor - mais de 30 kg (GG)",
                          style: {
                            fontFamily: "Poppins",
                            fontSize: 16,
                          },
                        },
                        //input
                        {
                          keyid: "value.5",
                          type: "inputCurrency",
                          placeHolder: "0,00",
                          style: {
                            ...inputStyle,
                            input: {
                              backgroundColor:
                                valueGG === 0 ? "#d3d3d3" : "transparent",
                              border: "none",
                            },
                            coin: { display: "none" },
                            box: {
                              alignItems: "center",
                              justifyContent: "center",
                            },
                          },
                          value: valueGG,
                          set: (e, value) => {
                            let price = parseFloat(e.target.value);
                            if (price !== 0) {
                              setValueGG(value);
                            } else {
                              setValueGG(0);
                            }
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            3: {
              style: {
                display: "flex",
                width: "100%",
                heigth: "100%",
                flexDirection: "column",
                gap: 8,
                padding: 8,
              },
              input: [
                //descrição
                {
                  type: "div",
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    heigth: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  },
                  children: [
                    //text
                    {
                      type: "text",
                      text: "Descrição",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 16,
                      },
                    },
                    //button
                    {
                      id: "description",
                      type: "input",
                      style: {
                        ...inputStyle,
                        input: {
                          maxWidth: 456,
                          border: "1px solid #bfbfbf",
                          borderRadius: 4,
                          padding: 4,
                          fontSize: 14,
                          backgroundColor: "transparent",
                        },
                      },
                      set: (e) => {
                        setDescription(e);
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
      }),
    ],
  };
};
