// Init Page para valida algumas infoamções e selcionar para qual pagina direcionar..

// verificar historico, ou url com parametros..

import { useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";
import ListView from "components/web/view/list";

import Theme from "theme";

//images
import EditService from "assets/images/menu/edit.svg";
import ActiveService from "assets/images/menu/eye-slash.svg";
import DisabledService from "assets/images/menu/eye.svg";
import DeleteService from "assets/images/menu/trash.svg";

import IconAdestrador from "assets/images/service_category/adestrador.png";
import IconBanhoEtosa from "assets/images/service_category/banhoEtosa.png";
import IconDayCare from "assets/images/service_category/dayCare.png";
import IconPasseador from "assets/images/service_category/passeador.png";
import IconVeteriário from "assets/images/service_category/veterinario.png";

export default (props) => {
  const { PageInfo, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  const [category, setCategory] = useState({});

  useEffect(async () => {}, []);

  // Business - Lista
  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "services-list" ? "flex" : "none",
      // backgroundColor: "red",
    },
    children: [
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          ListView({
            id: "services-list",
            title: "Serviços",
            buttonTopBar: {
              title: "Adicionar novo serviço",
              onPress: () => {
                GoPage("service-add");
              },
            },
            filter: [
              //banho e tosa
              {
                id: "bathAndGrooming",
                type: "button",
                label: "Banho e tosa",
                image: IconBanhoEtosa,
                query: "1",
                // count: ,
                style: {
                  box: {
                    width: 192,
                    border: "1px solid #ECECEC",
                    borderRadius: 4,
                  },
                  count: {
                    display: "none",
                  },
                },
              },
              //veterinário
              {
                id: "veterinarian",
                type: "button",
                label: "Veterinário",
                image: IconVeteriário,
                query: "2",
                style: {
                  box: {
                    width: 192,
                    border: "1px solid #ECECEC",
                    borderRadius: 4,
                  },
                  count: {
                    display: "none",
                  },
                },
              },
              //dayCare
              {
                id: "daycare",
                type: "button",
                label: "Daycare",
                image: IconDayCare,
                query: "3",
                style: {
                  box: {
                    width: 192,
                    border: "1px solid #ECECEC",
                    borderRadius: 4,
                  },
                  count: {
                    display: "none",
                  },
                },
              },
              //adestrador
              {
                id: "trainer",
                type: "button",
                label: "Adestrador",
                image: IconAdestrador,
                query: "5",
                style: {
                  box: {
                    width: 192,
                    border: "1px solid #ECECEC",
                    borderRadius: 4,
                  },
                  count: {
                    display: "none",
                  },
                },
              },
              //passeador
              {
                id: "walker",
                type: "button",
                label: "Passeador",
                image: IconPasseador,
                query: "6",
                style: {
                  box: {
                    width: 192,
                    border: "1px solid #ECECEC",
                    borderRadius: 4,
                  },
                  count: {
                    display: "none",
                  },
                },
              },
            ],
            style: {
              cel: (props) => {
                switch (props.col) {
                  case "visible": {
                    return {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent:
                        props.item.enabled === false
                          ? "flex-start"
                          : "flex-end",
                      width: 48,
                      height: "82%",
                      borderRadius: 10,

                      padding: 1,
                      backgroundColor:
                        props.item.enabled === false
                          ? "rgba(234, 236, 240, 1)"
                          : "blue",
                    };
                  }
                  case "value": {
                    return {
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "normal",
                    };
                  }
                  case "size": {
                    return {
                      display: "flex",
                      width: "auto",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      fontWeight: "normal",
                    };
                  }
                  case "enabled": {
                    return {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 24,
                      maxWidth: "100%",
                      borderRadius: 4,
                      padding: 8,
                      fontSize: Theme.fontSizes.row,
                      fontWeight: "600",
                      color:
                        props.item[props.col] === true
                          ? "rgba(5, 150, 105, 1)"
                          : "rgba(75, 85, 99, 1)",
                      backgroundColor:
                        props.item[props.col] === true
                          ? "rgba(167, 243, 208, 1)"
                          : "rgba(229, 231, 235, 1)",
                    };
                  }
                  default:
                    return {
                      fontWeight: "normal",
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 42,
                maxHeight: 42,
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
              filterBox: {
                backgroundColor: "transparent",
                width: "60%",
                justifyContent: "space-between",
                marginLeft: "1%",
              },
            },
            col: {
              animal_category: {
                key: ["animal_category", "name"],
                title: "Espécie",
              },
              category: {
                key: ["category", "name"],
                title: "Categoria",
              },
              sub_category: {
                // key: ["sub_category", "name"],
                title: "Serviço",
                style: {
                  flex: 2,
                },
                condition: (param) => {
                  if (param?.sub_category?.name) {
                    const name = param.sub_category.name;
                    return name.length > 40
                      ? `${name.substring(0, 40)}...`
                      : name;
                  }
                  return "";
                },
              },
              description: {
                title: "Descrição",
                condition: (param) => {
                  if (param?.description === "") {
                    return "Sem descrição";
                  } else {
                    return param.description;
                  }
                },
              },
              size: {
                key: ["size", "initials"],
                title: "Porte",
                style: {
                  flex: 0.5,
                },
              },
              value: {
                title: "Valor",
                style: {
                  flex: 1,
                },
                condition: (param) => {
                  return "R$ " + param?.value.toFixed(2);
                },
              },
              enabled: {
                title: "Status",
                condition: (param) => {
                  if (param.enabled === true) {
                    return "Ativo";
                  } else {
                    return "Inativo";
                  }
                },
              },
              edit: {
                title: "Ações",
                style: {
                  justifyContent: "center",
                  gap: 8,
                },
                item: [
                  // {
                  //   value: ({ row }) => {}
                  // },
                  //delet
                  {
                    type: "image",
                    src: DeleteService,
                    size: 28,
                    style: {
                      iconBox: {
                        width: "auto",
                        display: "flex",
                        border: "none",
                        alignItems: "center",
                        cursor: "pointer",
                      },
                    },
                    onClick: async (info) => {
                      PopupShow({
                        title: `Deseja excluir o serviço ${info?.sub_category?.name}?`,
                        description: "",
                        style: {
                          box: {
                            width: "auto",
                            height: "auto",
                            alignItems: "center",
                          },
                          close: {
                            display: "none",
                          },
                        },
                        cancelText: "cancelar",
                        confirmText: "deletar",
                        onCancel: async () => {},
                        onConfirm: async () => {
                          // await api.management.delete_user({ id: info._id });
                          // setUpdate("user-management");
                          await api.provider.serviceDelet({ id: info._id });
                          setUpdate("services-list" + new Date());
                        },
                      });
                    },
                  },
                  //edit
                  {
                    type: "image",
                    src: EditService,
                    size: 28,
                    style: {
                      iconBox: {
                        width: "auto",
                        display: "flex",
                        border: "none",
                        alignItems: "center",
                        cursor: "pointer",
                      },
                    },
                    onClick: async (info) => {
                      GoPage("service-edit", { info: info });
                    },
                  },
                  ,
                  {
                    type: "div",
                    id: "visible",
                    style: {
                      position: "absolute",
                      border: "1px solid black",
                      display: "flex",
                      flexDirection: "row",
                    },
                    onClick: async (item) => {
                      const show = await api.provider.serviceDisable({
                        _id: item._id,
                        enabled: !item.enabled,
                      });

                      item.enabled = show?.data?.data?.status;
                      setUpdate("services-list" + new Date());
                      return item;
                    },
                    children: [
                      {
                        type: "div",
                        style: {
                          flex: 1,
                          display: "flex",
                          height: "100%",
                          width: "auto",
                          aspectRation: "1/1",
                          maxWidth: 18,
                          maxHeight: "100%",
                          borderRadius: "50%",
                          backgroundColor: "white",
                        },
                      },
                    ],
                  },
                ],
              },
            },
            item: api.provider.service,
          }),
        ],
      },
    ],
  };
};
