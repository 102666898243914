import Theme from "theme";
import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import { ShowPopup } from "context/popup";

//api
import api from "service";

//components
import Input from "components/web/input";
import Button from "components/web/button";
import Switch from "components/web/switch";

//images
import Logo from "assets/images/logo.png";
import DogWoman from "assets/images/iconDogEpeople.svg";
import ShieldCheck from "assets/images/ShieldCheck.svg";

export default ({ content, setContent, setUserEmail, setLoadingSave }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("");

  const [codeChange, setCodeChange] = useState(false);

  const { PopupShow, PopupClose } = ShowPopup();

  useEffect(() => {
    if (window.location.href.includes("admin")) setUserType("E-mail");
    if (
      window.location.href.includes("provider") ||
      window.location.href.includes("business")
    ) {
      if (codeChange === true) {
        setUserType("CPF");
      } else {
        setUserType("CNPJ");
      }
    }
  }, [codeChange]);

  const inputStyle = {
    input: {
      width: "100%",
      height: 44,
      border: "1px solid #bfbfbf",
      borderBottom: "1px solid #bfbfbf",
      borderRadius: 8,
      padding: 4,
      fontSize: 18,
    },
    title: {
      fontFamily: "Poppins",
      fontSize: 14,
      color: "rgba(52, 64, 84, 1)",
    },
  };

  const inputEmail = Input({
    title:
      userType === "E-mail"
        ? "Digite o E-mail de cadastro"
        : "" || userType === "CNPJ"
        ? "Digite o CNPJ de cadastro"
        : "Digite o CPF de cadastro",
    mask:
      userType === "CNPJ"
        ? "99.999.999/9999-99"
        : "" || userType === "CPF"
        ? "999.999.999-99"
        : "",
    value: username,
    setValue: (value) => {
      setUsername(value);
    },
    style: {
      ...inputStyle,
    },
  });

  return {
    render: (
      <Fade>
        {Render({
          component: "container",
          style: {
            display: content === "Recovery" ? "flex" : "none",
            backgroundColor: "white",
            width: "28vw",
            height: "auto",
            marginTop: 32,
            borderRadius: 4,
            padding: "4%",
            border: "1px solid #E6E6E6",
            flexDirection: "column",
            gap: 28,
          },
          children: [
            //titleForm
            {
              component: "container",
              style: {
                width: "100%",
                display: "flex",
                alignItems: "center",
                height: "auto",
                flexDirection: "row",
              },
              children: [
                //titleForm
                {
                  component: "text",
                  text: "Recuperação de senha",
                  style: {
                    width: "100%",
                    fontFamily: "Poppins",
                    textAlign: "flex-start",
                    fontWeight: "600",
                    fontSize: 20,
                    color: "#05332F",
                  },
                },
                {
                  component: "container",
                  style: {
                    display: userType !== "E-mail" ? "flex" : "none",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height: "auto",
                  },
                  children: [
                    Switch({
                      title: "Pessoa física ?",
                      checked: codeChange,
                      value: codeChange,
                      style: {
                        switch: {
                          height: "auto",
                        },
                        title: {
                          color: codeChange === true ? "black" : "gray",
                          fontSize: 14,
                          fontFamily: "Poppins",
                        },
                      },
                      onChange: () => {
                        setCodeChange(!codeChange);
                      },
                    }),
                  ],
                },
              ],
            },
            //inputs (pensar em um children.map de inputs)
            {
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                height: "auto",
                flexDirection: "column",
                gap: 12,
              },
              children: [inputEmail],
            },
            //Voltar para login
            {
              component: "text",
              text: "Fazer login",
              style: {
                fontSize: 14,
                width: "28%",
                fontFamily: "Poppins",
                cursor: "pointer",
                color: "rgba(0, 166, 96, 1)",
              },
              onClick: () => {
                setContent("Login");
              },
            },
            //Botão enviar
            {
              component: "container",
              style: {
                display: "flex",
                width: "auto",
                height: "auto",
              },
              children: [
                Button({
                  label: "Recuperar senha",
                  style: {
                    button: {
                      backgroundColor: "white",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 44,
                      backgroundColor: "rgba(0, 166, 96, 1)",
                      borderRadius: 6,
                      border: "none",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                      color: "White",
                    },
                  },
                  onPress: async () => {
                    setLoadingSave(true);
                    const { data } = await api.auth.sent_email({
                      type: userType,
                      code: username,
                    });
                    // setContent("EmailSent");
                    // setLoadingSave(false);
                    // setUserEmail(data?.data?.email);
                    // console.log("result", data);
                    if (data?.data?.status === true) {
                      setContent("EmailSent");
                      setLoadingSave(false);
                      setUserEmail(data?.data?.email);
                    } else {
                      setLoadingSave(false);
                      PopupShow({
                        description: "",
                        style: {
                          box: {
                            width: "auto",
                            alignItems: "center",
                          },
                          close: {
                            display: "none",
                          },
                        },
                        item: [
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "center",
                              flexDirection: "column",
                              width: "100%",
                              gap: 12,
                            },
                            children: [
                              {
                                component: "text",
                                text:
                                  username.length === 0
                                    ? "Preencha o campo!"
                                    : "Erro ao enviar email!",
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: 18,
                                },
                              },
                            ],
                          },
                        ],
                        buttons: [
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row",
                              gap: 8,
                              marginTop: 12,
                            },
                            children: [
                              //btn fechar
                              Button({
                                label: "Tente novamente",
                                style: {
                                  button: {
                                    backgroundColor: "#FFB7B7",
                                    width: "100%",
                                    alignItems: "center",
                                    height: 42,
                                    borderRadius: 6,
                                    border: "none",
                                  },
                                  label: {
                                    fontFamily: Theme.fontFamilies.primary,
                                    fontSize: 18,
                                    color: "#DC2626",
                                  },
                                },
                                onPress: async () => {
                                  PopupClose();
                                },
                              }),
                            ],
                          },
                        ],
                      });
                    }
                  },
                }),
              ],
            },
          ],
        })}
      </Fade>
    ),
  };
};
