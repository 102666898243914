import { Profiler, useEffect, useState } from "react";

import api from "service";

import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

import ContentTopBar from "components/web/ContentTopBar";
import CategoryList from "components/web/categoryList/promotion";
import Button from "components/web/button";

import { Context } from "context/data";

import Theme from "theme";

import Arrow from "assets/images/menu/arrowBack.svg";

import IconBanho from "assets/images/menu/icon-shampoo.png";
import IconVet from "assets/images/menu/icon-veterinario.png";
import IconDayCare from "assets/images/menu/icon-daycare.png";
import IconAdestrador from "assets/images/menu/icon-adestrador.png";
import IconPasseador from "assets/images/menu/icon-passeador.png";
// srcTopBar: Arrow,

import SaveDisk from "assets/images/buttons/disk.svg";
import Edit from "assets/images/buttons/edit.svg";

export default () => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const { Data, Set } = Context();

  const [isEdit, setIsEdit] = useState(false);

  const [promotion, setPromotion] = useState({});

  useEffect(async () => {
    let cPromotion = {};
    Data.user?.promotion?.map((item) => {
      cPromotion[item.category] = item;
    });
    setPromotion(cPromotion);
    if (PageInfo.show !== "promotion") setIsEdit(false);
  }, [PageInfo.show, isEdit]);

  //promomções
  return {
    component: "container",
    style: {
      display: PageInfo.show === "promotion" ? "flex" : "none",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100%",
      padding: "1%",
      overflowX: "auto",
      overflowY: "hidden",
    },
    children: [
      //topBar
      ContentTopBar({
        title: "Promoções",
      }),
      //text
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          paddingLeft: "2%",
          marginTop: 24,
          marginBottom: 24,
          flexDirection: "column",
        },
        children: [
          {
            component: "text",
            text: "Com promoções exclusivas, você pode atrair ainda mais clientes! Todo desconto é integralmente revertido",
            style: {
              width: "100%",
              fontSize: 16,
              color: "rgba(117, 117, 117, 1)",
              fontFamily: "Poppins",
            },
          },
          {
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              flexDirection: "row",
              gap: 4,
            },
            children: [
              {
                component: "text",
                text: "para o usuário, que visualiza em tempo real as promoções!",
                style: {
                  fontSize: 16,
                  color: "rgba(117, 117, 117, 1)",
                  fontFamily: "Poppins",
                },
              },
              {
                component: "text",
                text: "Obs: este valor é adicional a taxa da Petin.",
                style: {
                  fontSize: 16,
                  fontWeight: "700",
                  color: "rgba(117, 117, 117, 1)",
                  fontFamily: "Poppins",
                },
              },
            ],
          },
        ],
      },
      //componentDePromoções
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          paddingLeft: "2%",
          flexDirection: "column",
          gap: 24,
          overflowX: "auto",
          overflowY: "hidden",
        },
        children: [
          CategoryList({
            edit: isEdit,
            category: promotion,
            onChange: {
              switch: (itemId, status) => {
                if (promotion?.disabled) {
                  promotion[itemId].disabled = status;
                } else {
                  promotion[itemId] = {
                    category: itemId,
                    disabled: status,
                    value: 0,
                  };
                }
              },
              inputValue: (itemId, value, status) => {
                promotion[itemId] = {
                  category: itemId,
                  disabled: status,
                  value: value,
                };
              },
            },
          }),
          //button editar/salvar
          {
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              height: "auto",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 18,
            },
            children: [
              //cancelar
              Button({
                style: {
                  button: {
                    display: isEdit === true ? "flex" : "none",
                    height: 34,
                    width: 128,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 4,
                    border: "none",
                    backgroundColor: " #E4EEFF",
                  },
                  label: {
                    fontSize: 14,
                    color: Theme.colors.blue.life,
                    fontFamily: "Poppins",
                  },
                },
                label: "cancelar",
                onPress: () => {
                  PopupShow({
                    title: "As alterações serão descartadas ao você sair.",
                    cancelText: "Sair",
                    confirmText: "Salvar",
                    onCancel: async () => {
                      let cPromotion = {};
                      Data.user?.promotion?.map((item) => {
                        cPromotion[item.category] = item;
                      });
                      setPromotion(cPromotion);
                      setIsEdit(!isEdit);
                      PopupClose();
                    },
                    onConfirm: async () => {
                      if (isEdit === true) {
                        const { data } = api.utils.category_promotion(
                        Object.values(promotion)
                       );
                      Set({
                        ...Data,
                        user: {
                          ...Data.user,
                          promotion: Object.values(promotion),
                        },
                      });
                      PopupClose();
                      setIsEdit(!isEdit);
                      } else {
                        PopupClose();
                        setIsEdit(!isEdit);
                        }
                    },
                  });
                },
              }),
              //editar
              Button({
                label: isEdit === true ? "Salvar" : "Editar",
                icon: isEdit === true ? SaveDisk : Edit,
                style: {
                  button: {
                    height: 34,
                    width: 128,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 4,
                    border: "none",
                    backgroundColor: Theme.colors.blue.life,
                  },
                  label: {
                    color: "white",
                  },
                  image: {
                    width: 22,
                    height: 22,
                  },
                },
                onPress: () => {
                  if (isEdit === true) {
                    const { data } = api.utils.category_promotion(
                      Object.values(promotion)
                    );
                    Set({
                      ...Data,
                      user: {
                        ...Data.user,
                        promotion: Object.values(promotion),
                      },
                    });
                    setIsEdit(!isEdit);
                  } else {
                    setIsEdit(!isEdit);
                  }
                },
              }),
            ],
          },
        ],
      },
    ],
  };
};
