import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/lara-light-cyan/theme.css";

export default (props) => {
  return {
    component: "container",
    style: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "flex-start",
      marginLeft: "0.5rem",
      justifyContent: "flex-start",
      padding: 4,
      gap: 2,
    },
    children: [
      {
        component: "text",
        text: props?.title,
        style: {
          fontSize: 12,
          fontFamily: "Poppins",
        },
      },
      {
        render: (
          <div className="card flex justify-content-center">
            <Dropdown
              value={props?.value}
              style={{
                display: "flex",
                width: 144,
                padding: 8,
                alignItems: "center",
                justifyContent: "space-between",
                height: 32,
                backgroundColor: "transparent",
                border: "1px solid #6D97E940",
              }}
              onChange={props?.onChange}
              options={props?.options}
              optionLabel={props?.optionLabel}
              placeholder={props?.placeholder}
              className="w-full md:w-14rem"
              disabled={props?.disabled}
            />
          </div>
        ),
      },
    ],
  };
};
