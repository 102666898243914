import { useEffect, useState } from "react";
import { ShowPage } from "context/showPage";
import AddView from "components/web/view/add";
import api from "service";
import { Context } from "context/data";

export default ({content, setContent}) => {
    const { PageInfo, GoPage } = ShowPage();
  const { Data, Set } = Context();

  const [daycareData, setDaycareData] = useState(null); // Estado para armazenar os dados da API
  const [switchRules, setSwitchRules] = useState({});
  const [visitValue, setVisitValue] = useState("");
  const [isVisitRequired, setIsVisitRequired] = useState(false);

  const dayCareRules = [
    "Ausência de doenças contagiosas",
    "Carteira de vacinação em dia",
    "Fêmeas não podem estar no cio",
    "Machos castrados",
    "Exame de fezes (giárdia)",
    "Não aceitam filhotes",
  ];

  const rulesMapping = {
    "Ausência de doenças contagiosas": "absence_contagious_diseases",
    "Carteira de vacinação em dia": "vaccination_card",
    "Fêmeas não podem estar no cio": "females_cannot_heat",
    "Machos castrados": "castrated_males",
    "Exame de fezes (giárdia)": "stool_exam",
    "Não aceitam filhotes": "not_accept_puppies",
  };

  useEffect(() => {
    const fetchDaycareData = async () => {
      try {
        const { data } = await api.utils.getUser({
          query: {
            code: "", // Substitua pelo valor necessário
            type: "provider",
          },
        });

        const daycare = data?.data?.info?.daycare;

        if (daycare) {
          // Inicializando valores do formulário
          const initialSwitches = dayCareRules.reduce((acc, rule) => {
            const backendKey = rulesMapping[rule];
            acc[rule] = daycare.requirements?.[backendKey] || false;
            return acc;
          }, {});

          setSwitchRules(initialSwitches);
          setIsVisitRequired(daycare.visit_mandatory || false);
          setVisitValue(daycare.visit_value ? daycare.visit_value.toString() : "");
          setDaycareData(daycare);
        }
      } catch (error) {
        console.error("Error fetching daycare data:", error);
      }
    };

    fetchDaycareData();
    
  }, [content === "rules"]);

  const handleSwitchChange = (rule, value) => {
    setSwitchRules((prev) => ({
      ...prev,
      [rule]: value.target.checked,
    }));
  };

  const handleVisitSwitchChange = (value) => {
    setIsVisitRequired(value.target.checked);
  };

  const handleVisitValueChange = (value) => {
    const rawValue = value.replace(",", ".").replace(/[^0-9.]/g, "");
    const numericValue = parseFloat(rawValue);

    if (!isNaN(numericValue)) {
      setVisitValue(rawValue);
    } else {
      setVisitValue("");
    }
  };

  const handleSubmit = async () => {
    const mappedRequirements = Object.entries(switchRules).reduce((acc, [key, value]) => {
      acc[rulesMapping[key]] = value;
      return acc;
    }, {});

    const dataToSend = {
      description: daycareData?.description,
      requirements: mappedRequirements,
      visit_mandatory: isVisitRequired,
      visit_value: parseFloat(visitValue) || daycareData?.visit_value,
    };

    // console.log("Dados a serem enviados:", dataToSend);

    await api.daycare.provider_daycare(dataToSend);
    setContent("description")
  };

  const onCancel = () =>{
    setContent("description")
  }

  return {
    component: "container",
    style: {
      display: content === "rules" ? "flex" : "none",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100%",
      overflowX: "auto",
      overflowY: "auto",
      flex: 1,
      zIndex: 1,
    },
    children: [
      AddView({
        direction: "row",
        showTopBar: false,
        form: {
          onSave: handleSubmit,
          onCancel: onCancel,
          style: {
            main: {
              flexWrap: "nowrap",
              overflowY: "auto",
              overflowX: "hidden",
              width: "40vw",
              height: "100%",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              border: "1px solid #CBD5E1",
              borderRadius: 4,
              gap: 12,
              padding: "1%",
              marginBottom: 12,
              marginTop: 0,
            },
            box: {
              1: {},
            },
            btn: {
              cancel: {},
              save: {},
              style: {
                width: "40vw",
              },
            },
          },
          box: {
            1: {
              style: {
                width: "100%",
                heigth: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
                gap: 8,
              },
              input: [
                {
                  component: "text",
                  text: "Configure abaixo os requisitos necessários para o recebimento de pets no seu estabelecimento:",
                  style:{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#757575",
                    paddingBottom: "15px",
                  },
                },
                ...dayCareRules.map((rules) => ({
                  type: "div",
                  key: rules,
                  style: {
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  },
                  children: [
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "no-wrap",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "100%",
                        flexFlow: "none",
                      },
                      children: [
                        {
                          type: "div",
                          style: {
                            display: "flex",
                            flex: "none",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            width: "80%",
                            gap: "0",
                          },
                          children: [
                            {
                              type: "switch",
                              label: rules,
                              style: {
                                display: "flex",
                                flexDirection: "row-reverse",
                                fontFamily: "Poppins",
                                fontSize: 16,
                                fontWeight: 500,
                                color: "#313131",
                              },
                              onChange: (value) => handleSwitchChange(rules, value),
                              checked: !!switchRules[rules],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                })),
              ],
            },
            2:{
              style: {
                display: "flex",
                width: "95%",
                flexDirection: "column",
                padding: 8,
                justifyContent: "space-around",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
              },
              input: [
                {
                  type: "div",
                  style: {
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    paddingTop: "1rem",
                  },
                  children: [
                    {
                          type: "div",
                          style: {
                            display: "flex",
                            flex: "none",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            width: "80%",
                            gap: "0",
                          },
                          children: [
                            {
                              type: "switch",
                              label: "Visita de adaptação obrigatória",
                              style: {
                                display: "flex",
                                marginLeft: -5,
                                flexDirection: "row-reverse",
                                fontFamily: "Poppins",
                                fontSize: 16,
                                fontWeight: 500,
                                color: "#313131",
                              },
                              onChange: handleVisitSwitchChange,
                              checked: isVisitRequired,
                            },
                          ],
                        },
                    {
                      component: "text",
                      text: "Valor cobrado na visita de adaptação ",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: 500,
                        color: "#535763",
                        marginLeft: 10,
                      },
                    },
                    {
                      id: "visit_value",
                      value: `R$ ${visitValue}`,
                      set: (value) => handleVisitValueChange(value),
                      type: "input",
                      disabled: !isVisitRequired,
                      style: {
                        border: "1px solid #D0D5DD",
                        borderRadius: 8,
                        padding: 4,
                        fontSize: "1.5vh",
                        width: "13vw",
                        height: "44px",
                        marginLeft: 10,
                        backgroundColor: isVisitRequired ? "transparent" : "#cdcdcd",
                      },
                      required: true,
                    },
                  ],
                },
              ],
            },
          },
        },
      }),
    ],
  };
};

