import { Render } from "@8_dev/sjson-web";
import Theme from "theme";
import api from "service";
// import { Render } from "render";

import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Context } from "context/data";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

import Navbar from "components/web/navbar";

import HourIcon from "assets/images/menu/icon-clock.svg";
import IconHome from "assets/images/menu/home.svg";
import OrderIcon from "assets/images/menu/icon-order.svg";
import ServiceIcon from "assets/images/menu/icon-services.svg";
import PromotionIcon from "assets/images/menu/icon-promotion.svg";

import Arrow from "assets/images/menu/arrowDown.svg";

import Profile from "pages/profile";
import Home from "pages/home";
import Order from "pages/order";
import Schedules from "pages/schedules";
import Services from "pages/providerServices";
import Promotion from "pages/promotion";

export default () => {
  const { Data, Set } = Context();
  const { GoInitPage, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  const [profile, setProfile] = useState({});

  useEffect(async () => {
    setProfile(Data.user);
  }, [Data.user]);

  // let Pages = [];
  const Nav = {
    component: "container",
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    children: [
      Navbar({
        profile: {
          page: "Profile",
          name: profile?.name || profile?.nickname,
          email: profile?.email,
          iconProfile: profile?.company_icon_thumb,
        },
        item: [
          //Inicio
          {
            title: "Início",
            page: "Home",
            image: {
              src: IconHome,
            },
          },
          // Pedidos
          {
            title: "Pedidos",
            page: "orders",
            image: {
              src: OrderIcon,
            },
          },
          // Horários
          {
            title: "Horários",
            page: "schedules",
            image: {
              src: HourIcon,
            },
          },
          // Serviços
          {
            title: "Serviços",
            page: "services-list",
            image: {
              src: ServiceIcon,
            },
          },
          // Promoções
          {
            title: "Promoções",
            page: "promotion",
            image: {
              src: PromotionIcon,
            },
          },
        ],
      }),
    ],
  };

  const PageStyle = {
    display: "flex",
    flexDirection: "row",
    width: "100vw",
    minWidth: "100vw",
    maxWidth: "100vw",
    height: "100vh",
    maxHeight: "100vh",
    minHeight: "100vh",
    overflow: "hidden",
  };

  const Pages = [
    Nav,
    ...Profile(),
    ...Home(),
    ...Order(),
    ...Schedules(),
    ...Services(),
    ...Promotion(),
  ];
  return {
    component: "container",
    style: PageStyle,
    children: Pages,
  };
};
