import { Render } from "@8_dev/sjson-web";
import { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { ShowPopup } from "context/popup";
import InputMask from "react-input-mask";

//context
import { Context } from "context/data";
import { ShowPage } from "context/showPage";

//theme
import Theme from "theme";

//api
import api from "service";

//components
import ContentTopBar from "components/web/ContentTopBar";
import Switch from "components/web/switch";
import InputNumber from "components/web/inputNumber";
import Slider from "components/web/slider";
import CurrencyInput from "components/web/inputCurrency";

//images
import EditProvider from "assets/images/edit-user.png";
import iconProfile from "assets/images/menu/avatar-profile.svg";
import ProviderIcon from "assets/images/menu/icon-provider.svg";
import PhoneIcon from "assets/images/menu/icon-phone.svg";
import Arrow from "assets/images/menu/arrowBack.svg";

//pages
import Edit from "./edit";

export default (props) => {
  const { Data, Set } = Context();
  const { PageInfo, GoPage } = ShowPage();

  const [popUp, setPopUp] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [price, setPrice] = useState("");
  const [deliveryRange, setDeliveryRange] = useState("5");
  // const label = { inputProps: { "aria-label": "Switch demo" } };

  let marks = {};
  for (let i = 0; i <= 10; i += 0.5) {
    marks[i] = {
      style: { fontSize: 12 },
      label: i + "",
    };
  }

  const [profile, setProfile] = useState({});

  function formatCode(code) {
    if (code.length === 14) {
      // Formata como CNPJ
      return code.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        "$1.$2.$3/$4-$5"
      );
    } else if (code.length === 11) {
      // Formata como CPF
      return code.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    }
    return code;
  }

  useEffect(async () => {
    setProfile(Data.user);
    setIsActive(Data.user?.delivery_service);
    if (PageInfo.show === "Profile") {
      setPrice(Data.user?.delivery_service_value?.toFixed(2));
    }
    setDeliveryRange(Data.user?.delivery_range);
  }, [PageInfo.show, Data.user]);

  return {
    component: "container",
    style: {
      width: "100%",
      height: "100%",
      display: "flex",
      display: PageInfo.show === "Profile" ? "flex" : "none",
    },
    children: [
      Edit(popUp, setPopUp),
      {
        render: (
          <Fade>
            {Render({
              component: "container",
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                padding: 16,
                gap: "1%",
                flexDirection: "column",
              },
              children: [
                ContentTopBar({
                  title: "Seu perfil",
                  // src: Arrow,
                }),
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "64vw",
                    minHight: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "1%",
                    border: "1px solid #CBD5E1",
                    borderRadius: 4,
                    gap: 16,
                  },
                  children: [
                    //topContentProfile - meus dados
                    {
                      component: "container",
                      style: {
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      },
                      children: [
                        {
                          component: "text",
                          text: "Meus dados",
                          style: {
                            fontFamily: "Poppins",
                            fontSize: 16,
                            fontWeight: "bold",
                          },
                        },
                        {
                          component: "image",
                          src: EditProvider,
                          style: {
                            cursor: "pointer",
                            width: 24,
                            height: 24,
                          },
                          onClick: () => {
                            setPopUp(true);
                          },
                        },
                      ],
                    },
                    //meus-dados
                    {
                      component: "container",
                      style: {
                        flex: 1,
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        borderRadius: "4px",
                        backgroundColor: "#EAEAEA",
                        padding: "2%",
                      },
                      children: [
                        //iconProfile
                        {
                          component: "image",
                          src: profile?.company_icon || iconProfile,
                          style: {
                            borderRadius: "50%",
                            border: "1px solid #CBD5E1",
                            minWidth: 128,
                            maxWidth: 128,
                            minHeight: 128,
                            maxHeight: 128,
                          },
                        },
                        //contentProfile
                        {
                          component: "container",
                          style: {
                            width: "100%",
                            height: "100%",
                            padding: 6,
                            paddingTop: 12,
                            gap: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          },
                          children: [
                            //topInfoProfile
                            {
                              component: "container",
                              style: {
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              },
                              children: [
                                //textName
                                {
                                  component: "text",
                                  text:
                                    profile?.name || profile?.nickname || "-_-",
                                  style: {
                                    fontFamily: "Poppins",
                                    marginLeft: 6,
                                    fontSize: 16,
                                    fontWeight: "bold",
                                  },
                                },
                                //textCode
                                {
                                  component: "container",
                                  style: {
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "auto",
                                    gap: 4,
                                  },
                                  children: [
                                    {
                                      component: "text",
                                      text:
                                        profile?.code?.length === 14
                                          ? "CNPJ: "
                                          : "CPF: ",
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: 14,
                                      },
                                    },
                                    {
                                      component: "text",
                                      text: profile?.code
                                        ? formatCode(profile.code)
                                        : "-_-",
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: 14,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                            //InfoAddress
                            {
                              component: "container",
                              style: {
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "image",
                                  src: ProviderIcon,
                                  style: {},
                                },
                                {
                                  component: "text",
                                  text:
                                    profile?.address?.street +
                                      ", " +
                                      profile?.address?.district +
                                      ", " +
                                      profile?.address?.number +
                                      ", " +
                                      profile?.address?.city +
                                      "-" +
                                      profile?.address?.state +
                                      ", " +
                                      profile?.address?.code || "-_-",
                                  style: {
                                    fontFamily: "Poppins",
                                    maxWidth: "50%",
                                    fontSize: 14,
                                    flexWrap: "wrap",
                                  },
                                },
                              ],
                            },
                            //InfoPhone
                            {
                              component: "container",
                              style: {
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "image",
                                  src: PhoneIcon,
                                  style: {},
                                },
                                {
                                  component: "text",
                                  text: profile?.whatsapp || "-_-",
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: 14,
                                    flexWrap: "wrap",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    //topContentProfile - leva e traz
                    {
                      component: "container",
                      style: {
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      },
                      children: [
                        {
                          component: "text",
                          text: "Leva e traz",
                          style: {
                            fontFamily: "Poppins",
                            fontSize: 16,
                            fontWeight: "bold",
                          },
                        },
                      ],
                    },
                    //leva e traz
                    {
                      component: "container",
                      style: {
                        flex: 1,
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        borderRadius: "4px",
                        backgroundColor: "#EAEAEA",
                        padding: "2%",
                      },
                      children: [
                        //text
                        {
                          component: "text",
                          text: "Se o seu estabelecimento possui serviço de retirada e entrega do pet, configure as informações abaixo: ",
                          style: {
                            width: "100%",
                            fontFamily: "Poppins",
                            textAlign: "center",
                            fontSize: 14,
                          },
                        },
                        {
                          component: "container",
                          style: {
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 20,
                          },
                          children: [
                            //switch e text
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              },
                              children: [
                                Switch({
                                  checked: isActive,
                                  value: isActive,
                                  onChange: async (e) => {
                                    let { data } =
                                      await api.provider.attProvider({
                                        delivery_service: !isActive,
                                        delivery_service_value: price,
                                      });
                                    if (data.data.status) {
                                      Set({
                                        ...Data,
                                        user: data.data.info,
                                      });
                                    }
                                    setIsActive(!isActive);
                                  },
                                }),
                                {
                                  component: "text",
                                  text: isActive
                                    ? "Serviço ativo"
                                    : "Serviço inativo",
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                    textAlign: "center",
                                  },
                                },
                              ],
                            },
                            //raio de atendimento + value
                            {
                              component: "container",
                              style: {
                                display: isActive === true ? "flex" : "none",
                                width: "100%",
                                marginTop: 12,
                                flexDirection: "column",
                                alignItems: "flex-start",
                              },
                              children: [
                                //raio de atendimento
                                {
                                  component: "container",
                                  style: {
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    marginBottom: 12,
                                  },
                                  children: [
                                    InputNumber({
                                      title: "Raio de atendimento",
                                      min: 0,
                                      max: 10,
                                      onChange: async (value) => {
                                        setDeliveryRange(value);
                                        let { data } =
                                          await api.provider.attProvider({
                                            delivery_service: isActive,
                                            delivery_service_value:
                                              parseFloat(price),
                                            delivery_range: value,
                                          });
                                        if (data.data.status) {
                                          Set({
                                            ...Data,
                                            user: data.data.info,
                                          });
                                        }
                                      },
                                      value: deliveryRange,
                                      addonAfter: " Km",
                                      step: "0.5",
                                    }),
                                    Slider({
                                      style: {
                                        display: "flex",
                                        width: "90%",
                                      },
                                      min: 0,
                                      max: 10,
                                      value: deliveryRange,
                                      step: 0.5,
                                      defaultValue: 5,
                                      marks: marks,
                                      onChange: async (value) => {
                                        setDeliveryRange(value);
                                        let { data } =
                                          await api.provider.attProvider({
                                            delivery_service: isActive,
                                            delivery_service_value:
                                              parseFloat(price),
                                            delivery_range: value,
                                          });
                                        if (data.data.status) {
                                          Set({
                                            ...Data,
                                            user: data.data.info,
                                          });
                                        }
                                      },
                                      tooltip: (value) => `${value} Km`,
                                    }),
                                  ],
                                },
                                CurrencyInput({
                                  title:
                                    "Selecione o valor cobrado por quilometro",
                                  value: price,
                                  style: {
                                    width: 150,
                                    height: 42,
                                    borderRadius: 4,
                                    color: "rgba(100,100,100,1)",
                                    fontSize: 18,
                                    fontFamily: "Poppins",
                                    borderWidth: 0,
                                    backgroundColor: "rgba(100,100,100,0.1)",
                                    paddingLeft: "1%",
                                  },
                                  onChange: async (e, value) => {
                                    if (value.length > 8) return 0;
                                    else if (value === "") setPrice("");
                                    else {
                                      setPrice(value);
                                      let { data } =
                                        await api.provider.attProvider({
                                          delivery_service: isActive,
                                          delivery_service_value:
                                            parseFloat(value),
                                        });
                                      if (data.data.status) {
                                        Set({
                                          ...Data,
                                          user: data.data.info,
                                        });
                                      }
                                    }
                                  },
                                }),
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    //topContentProfile - dados bancários
                    {
                      component: "container",
                      style: {
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      },
                      children: [
                        {
                          component: "text",
                          text: "Dados bancários",
                          style: {
                            fontFamily: "Poppins",
                            fontSize: 16,
                            fontWeight: "bold",
                          },
                        },
                      ],
                    },
                    //Dados bancários
                    {
                      component: "container",
                      style: {
                        flex: 1,
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        borderRadius: "4px",
                        backgroundColor: "#EAEAEA",
                        padding: "2%",
                      },
                      children: [
                        //Conteúdo bancário
                        {
                          component: "container",
                          style: {
                            width: "100%",
                            display: "flex",
                            height: 88,
                            flexDirection: "row",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            gap: 12,
                            marginTop: 12,
                          },
                          children: [
                            //text banco
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                width: "100%",
                                gap: 8,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Banco",
                                  style: {
                                    fontSize: 14,
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    fontWeight: "normal",
                                  },
                                },
                                {
                                  component: "text",
                                  text: profile?.bank_account?.bank?.name || "",
                                  style: {
                                    fontSize: 14,
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    color: "gray",
                                    fontWeight: "normal",
                                  },
                                },
                              ],
                            },
                            //text Agencia
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                width: "100%",
                                gap: 8,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Agência",
                                  style: {
                                    fontSize: 14,
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    fontWeight: "normal",
                                  },
                                },
                                {
                                  component: "text",
                                  text: profile?.bank_account?.agency || "",
                                  style: {
                                    fontSize: 14,
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    color: "gray",
                                    fontWeight: "normal",
                                  },
                                },
                              ],
                            },
                            //text conta
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                width: "100%",
                                gap: 8,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Conta",
                                  style: {
                                    fontSize: 14,
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    fontWeight: "normal",
                                  },
                                },
                                {
                                  component: "text",
                                  text: profile?.bank_account?.account || "",
                                  style: {
                                    fontSize: 14,
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    color: "gray",
                                    fontWeight: "normal",
                                  },
                                },
                              ],
                            },
                            //text tipo de chave
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                width: "100%",
                                gap: 8,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Tipo de chave",
                                  style: {
                                    fontSize: 14,
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    fontWeight: "normal",
                                  },
                                },
                                {
                                  component: "text",
                                  text:
                                    profile?.bank_account?.pix_type?.name || "",
                                  style: {
                                    fontSize: 14,
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    color: "gray",
                                    fontWeight: "normal",
                                  },
                                },
                              ],
                            },
                            //text cnpj
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                width: "100%",
                                gap: 8,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Chave",
                                  style: {
                                    fontSize: 14,
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    fontWeight: "normal",
                                  },
                                },
                                {
                                  component: "text",
                                  text: profile?.bank_account?.pix_key || "",
                                  style: {
                                    fontSize: 14,
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    color: "gray",
                                    fontWeight: "normal",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        //textPetin
                        {
                          component: "container",
                          style: {
                            width: "100%",
                            height: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                          },
                          children: [
                            {
                              component: "text",
                              text: "Para alterar os dados bancários entre em contato com a Petin",
                              style: {
                                fontSize: 14,
                                width: "auto",
                                textAlign: "center",
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            })}
          </Fade>
        ),
      },
    ],
  };
};
