import Theme from "theme";
import React, { useState, useEffect } from "react";
import { Slider, InputNumber } from "antd";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default (props) => {
  return {
    component: "container",
    style: {
      display: "flex",
      width: "auto",
      height: "auto",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      padding: 2,
      gap: 2,
      ...props?.style,
    },
    children: [
      {
        component: "text",
        text: props.title,
        style: {
          display: props?.title ? "flex" : "none",
          fontSize: 14,
          fontFamily: "Poppins",
          marginBottom: 8,
        },
      },
      {
        render: (
          <InputNumber
            min={props?.min}
            max={props?.max}
            style={{ flex: 0.2, ...props?.style }}
            onChange={props?.onChange}
            value={props?.value}
            addonAfter={props?.addonAfter}
            step={props?.step}
            disabled={props?.disabled}
          />
        ),
      },
    ],
  };
};
