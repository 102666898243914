import { useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import { Context } from "context/data";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
// Importações

import Theme from "theme";

import ImagePerson from "assets/images/bcg/petFriendly.png";
import Logo from "assets/images/camada_1.png";

// import Bcg from "assets/images/bcg.jpg";
import Input from "components/web/input";
import Access from "components/web/access";

import DogAudio from "assets/sounds/dog.mp3";
import { Howl, Howler } from "howler";

export default (props) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();

  let soundPlay = async (src, time, interval) => {
    const sound = new Howl({
      src,
      html5: true,
      volume: 0.5,
      loop: false,
    });
    for (let i = 0; i < time; i++) {
      await sleep((interval || 550) * i);
      sound.play();
    }
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const inputStyle = {
    input: {
      border: "1px solid #bfbfbf",
      borderBottom: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "2vh",
    },
    title: {
      ...Theme.text.login,
      fontSize: "1.5vh",
    },
  };

  const inptUsername = Input({
    title: "Usuário",
    value: username,
    setValue: setUsername,
    // mask: "99.999.999/9999-99",
    style: {
      ...inputStyle,
    },
  });

  const inptPassword = Input({
    type: "password",
    title: "Senha",
    value: password,
    setValue: setPassword,
    style: {
      ...inputStyle,
    },
  });

  const [userId, setUserId] = useState("");

  useEffect(async () => {}, []);

  return {
    render: (
      <div style={{ display: !Data.user?.status ? "flex" : "none" }}>
        <Fade>{Render(Access({
          title: "Painel do parceiro"
        }))}</Fade>
      </div>
    ),
  };
};
