import { Render } from "@8_dev/sjson-web";
import { ShowPage } from "context/showPage";
import api from "service";
import Table from "components/web/table";
import Button from "components/web/button";
import Fade from "react-reveal/Fade";
import ContentTopBar from "components/web/ContentTopBar";
import { useEffect } from "react";
import Theme from "theme";

export default (props) => {
  const { PageInfo, update } = ShowPage();

  const clientTableList = Table({
    col: props.col || {
      id: {
        title: "ID",
        style: { flex: 0.2 },
      },
    },
    filterby_key: props?.filterby_key,
    filterby_op: props?.filterby_op,
    filterby_val: props?.filterby_val,
    orderby: props?.orderby,
    orderasc: props?.orderasc,
    filter: props?.filter,
    item: props.item,
    style: {
      box: {
        flex: 1,
        padding: 0,
        margin: 0,
        ...props?.style?.box,
      },
      cel: props?.style?.cel,
      col: {
        flex: 1,
        borderRight: `2px solid rgba(100,100,100, 0.2)`,
        ...props?.style?.col,
        marginBottom: 8,
      },
      filterBox: props?.style?.filterBox,

      row: {
        // borderRight: `2px solid rgba(100,100,100, 0.2)`,
        borderTop: `1px solid rgba(100,100,100,0.2)`,
        padding: 0,
        margin: 0,
        ...props?.style?.row,
      },

      rowIndex: { ...props.style?.rowIndex },
    },
  });

  useEffect(async () => {
    if (PageInfo.show === props.id) clientTableList.update(props.item);
  }, [PageInfo.show, update]);

  useEffect(async () => {
    if (update?.includes(props.id)) clientTableList.update(props.item);
    console.log("verReq", update);
  }, [update]);

  useEffect(async () => {
    // verificar se essa pagina esta no historico ou na pagina que esta sendo exibida...
    // atualiza somente conteudo especifico,
    // salvar na page as configs de filtro
    // salvar filtros no usuario e tras depois no login
    clientTableList.update(props.item);
  }, [props.item]);

  useEffect(async () => {
    if (props?.style?.row) clientTableList.rowStyle(props.style.row);
  }, [props.style?.row]);

  return {
    render: (
      <div
        style={{
          overflowY: "hidden",
          width: "100%",
          maxWidth: "100%",
          height: "94%",
          maxHeight: "94%",
          zIndex: 2,
        }}
      >
        <Fade top cascade>
          {Render({
            component: "container",
            style: {
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            },
            children: [
              {
                component: "container",
                style: {
                  width: "100%",
                  padding: "1%",
                  marginBottom: 8,
                  borderRadius: 4,
                  display: props.topBar === false ? "none" : "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                },
                children: [
                  ContentTopBar({
                    title: props.title,
                    search: [],
                    action: [],
                  }),
                  Button({
                    label: props?.buttonTopBar?.title,
                    style: {
                      button: {
                        display: props?.buttonTopBar?.title ? "flex" : "none",
                        width: "auto",
                        minWidth: 224,
                        backgroundColor: Theme.colors.blue.life,
                        height: 42,
                        borderRadius: 4,
                        padding: 8,
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                        ...props?.buttonTopBar?.style?.button,
                      },
                      label: {
                        fontFamily: Theme.fontFamilies.primary,
                        fontSize: 18,
                        color: "White",
                        flexWrap: "nowrap",
                        ...props?.buttonTopBar?.style?.label,
                      },
                    },
                    onPress: async () => {
                      if (props?.buttonTopBar?.onPress) {
                        props?.buttonTopBar?.onPress();
                      }
                    },
                  }),
                ],
              },
              // },
              {
                component: "container",
                style: {
                  width: "100%",
                  borderRadius: 4,
                  padding: 8,
                  maxHeight: "90%",
                  flex: 1,
                },
                children: [clientTableList.component],
              },
              // table usar do RH
            ],
          })}
        </Fade>
      </div>
    ),
  };
};
