import Theme from "theme";
import React, { useState, useEffect } from "react";
import { Slider, InputNumber } from "antd";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default (props) => {
  return {
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: "auto",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 2,
      gap: 2,
    },
    children: [
      {
        render: (
          <Slider
            min={props?.min}
            max={props?.max}
            style={{
              flex: 1,
              fontSize: 12,
              marginBottom: "auto",
              ...props?.style,
            }}
            onChange={props?.onChange}
            value={props?.value}
            tooltip={props?.tooltip}
            step={props?.step}
            defaultValue={props?.defaultValue}
            marks={props?.marks}
          />
        ),
      },
    ],
  };
};
