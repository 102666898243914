import { Profiler, useEffect, useState } from "react";
import { Render } from "@8_dev/sjson-web";
import { Fade } from "react-reveal";
import InputMask from "react-input-mask";

import api from "service";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

import { Context } from "context/data";

import Select from "components/web/select";

import Theme from "theme";

import Close from "assets/images/menu/close.png";
import Loading from "../../../components/web/loading";
import Button from "../../../components/web/loading";

export default (popUp, setPopUp) => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const { Data, Set } = Context();

  const [profileLocal, setProfileLocal] = useState({});

  //Busca cep provider
  const [addrStateProfile, setAddrStateProfile] = useState("");
  const [addrCityProfile, setAddrCityProfile] = useState("");
  const [addrDistrictProfile, setAddrDistrictProfile] = useState("");
  const [addrStreetProfile, setAddrStreetProfile] = useState("");
  const [addrNumberProfile, setAddrNumberProfile] = useState("");
  const [addrComplementProfile, setAddrComplementProfile] = useState("");

  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(() => {
    setAddrStateProfile(Data.user?.address?.state);
    setAddrCityProfile(Data.user?.address?.city);
    setAddrDistrictProfile(Data.user?.address?.district);
    setAddrStreetProfile(Data.user?.address?.street);
    setAddrNumberProfile(Data.user?.address?.number);
    setAddrComplementProfile(Data.user?.address?.complement);
    setProfileLocal(Data.user);
  }, [Data.user]);

  const labelStyle = {
    width: "100%",
    textAlign: "flex-start",
    fontFamily: "Poppins",
    fontWeight: "normal",
    color: "black",
    fontWeight: "400",
    fontSize: "0.88vw",
  };

  const inputStyle = {
    maxHeight: 42,
    minHeight: "8%",
    width: "100%",
    height: "6vh",
    aspectRatio: 3,
    borderRadius: 8,
    border: "1px solid rgba(156, 163, 175, 1)",
    color: "rgba(156, 163, 175, 1)",
    fontSize: 16,
    fontFamily: "Poppins",
    paddingLeft: 4,
  };

  const selectTransfer_deadline = Select({
    value: profileLocal.transfer_deadline,
    item: [
      {
        _id: 1,
        name: "D + 1",
      },
      {
        _id: 30,
        name: "D + 30",
      },
    ],
    onChange: async (value) => {
      let transfer_deadline = value._id;
      setProfileLocal({
        ...profileLocal,
        transfer_deadline,
      });
    },
    style: {
      select: {
        maxHeight: 42,
        minHeight: "8%",
        maxWidth: 332,
        height: "6vh",
        aspectRatio: 3,
        borderRadius: 8,
        border: "1px solid rgba(156, 163, 175, 1)",
        color: "rgba(156, 163, 175, 1)",
        fontSize: 16,
        fontFamily: "Poppins",
        paddingLeft: 4,
        backgroundColor: "transparent",
      },
    },
  });

  useEffect(async () => {}, []);

  //editar parceiros
  return {
    component: "container",
    style: {
      display: popUp === true ? "flex" : "none",
      width: "100%",
      minHeight: "100vh",
      maxHeight: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      alignItems: "center",
      borderRadius: 0,
      zIndex: 99,
    },
    children: [
      //loading
      loadingSave && Loading(),
      {
        render: (
          <Fade>
            {Render({
              component: "container",
              style: {
                display: "flex",
                width: "52%",
                flexWrap: "wrap",
                height: "auto",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
                gap: 4,
                backgroundColor: "white",
                borderRadius: 8,
                padding: 12,
              },
              children: [
                //labelEdit
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  },
                  children: [
                    {
                      component: "text",
                      text: "Edite seu perfil",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: "1.44vw",
                        textAlign: "center",
                        fontWeight: "600",
                      },
                    },
                    {
                      component: "image",
                      src: Close,
                      style: {
                        width: 24,
                        aspectRatio: "1/1",
                        alignItems: "center",
                        cursor: "pointer",
                      },
                      onClick: async () => {
                        setProfileLocal(Data.user);
                        setAddrStateProfile(Data.user?.address?.state);
                        setAddrCityProfile(Data.user?.address?.city);
                        setAddrDistrictProfile(Data.user?.address?.district);
                        setAddrStreetProfile(Data.user?.address?.street);
                        setAddrNumberProfile(Data.user?.address?.number);
                        setAddrComplementProfile(
                          Data.user?.address?.complement
                        );
                        setPopUp(false);
                      },
                    },
                  ],
                },
                //infoGeral
                {
                  component: "container",
                  style: {
                    display: "flex",
                    marginTop: 12,
                    width: "100%",
                    flexDirection: "column",
                    gap: 12,
                  },
                  children: [
                    //label
                    {
                      component: "text",
                      text: "Informações gerais",
                      style: {
                        width: "100%",
                        textAlign: "flex-start",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        color: "blue",
                      },
                    },
                    //inputs
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        padding: 4,
                        paddingLeft: 0,
                        gap: 12,
                        flexDirection: "row",
                      },
                      children: [
                        //name
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "100%",
                            gap: 4,
                            flexDirection: "column",
                          },
                          children: [
                            //text
                            {
                              component: "text",
                              text: "Nome fantasia",
                              style: labelStyle,
                            },
                            //inputName
                            {
                              render: (
                                <InputMask
                                  value={profileLocal?.nickname}
                                  // mask={}
                                  // type={}
                                  style={inputStyle}
                                  onFocus={async (e) => {}}
                                  onBlur={async (e) => {}}
                                  onChange={async (text) => {
                                    let nickname = text.target.value;
                                    setProfileLocal({
                                      ...profileLocal,
                                      nickname,
                                    });
                                  }}
                                />
                              ),
                            },
                          ],
                        },
                        //whatsApp
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "100%",
                            gap: 4,
                            padding: 4,
                            flexDirection: "column",
                          },
                          children: [
                            //text
                            {
                              component: "text",
                              text: "WhatsApp",
                              style: labelStyle,
                            },
                            //inputWhats
                            {
                              render: (
                                <InputMask
                                  value={profileLocal?.whatsapp}
                                  mask={"(99) 99999-9999"}
                                  // type={}
                                  style={inputStyle}
                                  onFocus={async (e) => {}}
                                  onBlur={async (e) => {}}
                                  onChange={async (text) => {
                                    const whatsapp = text.target.value.replace(
                                      /[()\-\s]/g,
                                      ""
                                    );
                                    setProfileLocal({
                                      ...profileLocal,
                                      whatsapp,
                                    });
                                  }}
                                />
                              ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                //prazo de repasse
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "49%",
                    gap: 4,
                    flexDirection: "column",
                  },
                  children: [
                    //text
                    {
                      component: "text",
                      text: "Prazo de repasse",
                      style: labelStyle,
                    },
                    selectTransfer_deadline,
                  ],
                },
                //Endereço
                {
                  component: "container",
                  style: {
                    display: "flex",
                    marginTop: 12,
                    width: "100%",
                    flexDirection: "column",
                    gap: 12,
                  },
                  children: [
                    //label
                    {
                      component: "text",
                      text: "Localização",
                      style: {
                        width: "100%",
                        textAlign: "flex-start",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        color: "blue",
                      },
                    },
                    //inputs
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        padding: 4,
                        paddingLeft: 0,
                        gap: 12,
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                      children: [
                        //cep
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "30%",
                            gap: 4,
                            flexDirection: "column",
                          },
                          children: [
                            //text
                            {
                              component: "text",
                              text: "CEP",
                              style: labelStyle,
                            },
                            //inputCep
                            {
                              render: (
                                <InputMask
                                  value={profileLocal?.address?.code}
                                  mask={"99999-999"}
                                  // type={}
                                  // placeholder="Burno"
                                  style={inputStyle}
                                  onFocus={async (e) => {}}
                                  onBlur={async (e) => {}}
                                  onChange={async (e) => {
                                    let code = e.target.value.replace(
                                      /[^A-Za-z0-9 ]+/g,
                                      ""
                                    );
                                    setProfileLocal({
                                      ...profileLocal,
                                      address: {
                                        ...profileLocal?.address,
                                        code,
                                      },
                                    });
                                    //update
                                    if (code.length === 8) {
                                      const { data } =
                                        await api.utils.addressCode({
                                          query: { code: code },
                                        });
                                      if (data?.data?.cep) {
                                        setProfileLocal({
                                          ...profileLocal,
                                          address: {
                                            ...profileLocal?.address,
                                            code,
                                            street: data.data.street,
                                            state: data.data.state,
                                            city: data.data.city,
                                            district: data.data.neighborhood,
                                          },
                                        });
                                        setAddrStreetProfile(data.data.street);
                                        setAddrStateProfile(data.data.state);
                                        setAddrCityProfile(data.data.city);
                                        setAddrDistrictProfile(
                                          data.data.neighborhood
                                        );
                                      }
                                    } else {
                                    }
                                  }}
                                />
                              ),
                            },
                          ],
                        },
                        //logadouro
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "40%",
                            gap: 4,
                            padding: 4,
                            flexDirection: "column",
                          },
                          children: [
                            //text
                            {
                              component: "text",
                              text: "Logadouro",
                              style: labelStyle,
                            },
                            //inputLogadouro
                            {
                              render: (
                                <InputMask
                                  value={addrStreetProfile}
                                  // mask={}
                                  // type={}
                                  style={inputStyle}
                                  onFocus={async (e) => {}}
                                  onBlur={async (e) => {}}
                                  onChange={async (e) => {
                                    let street = e.target.value;
                                    setAddrStreetProfile(street);
                                    setProfileLocal({
                                      ...profileLocal,
                                      address: {
                                        ...profileLocal?.address,
                                        street,
                                      },
                                    });
                                  }}
                                />
                              ),
                            },
                          ],
                        },
                        //estado
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "24%",
                            gap: 4,
                            padding: 4,
                            flexDirection: "column",
                          },
                          children: [
                            //text
                            {
                              component: "text",
                              text: "Estado",
                              style: labelStyle,
                            },
                            //inputEstado
                            {
                              render: (
                                <InputMask
                                  value={addrStateProfile}
                                  // type={}
                                  style={inputStyle}
                                  onFocus={async (e) => {}}
                                  onBlur={async (e) => {}}
                                  onChange={async (e) => {
                                    let state = e.target.value;
                                    setAddrStateProfile(state);
                                    setProfileLocal({
                                      ...profileLocal,
                                      address: {
                                        ...profileLocal?.address,
                                        state,
                                      },
                                    });
                                  }}
                                />
                              ),
                            },
                          ],
                        },
                        //Numero
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "30%",
                            gap: 4,
                            flexDirection: "column",
                          },
                          children: [
                            //text
                            {
                              component: "text",
                              text: "Numero",
                              style: labelStyle,
                            },
                            //inputNumero
                            {
                              render: (
                                <InputMask
                                  value={addrNumberProfile}
                                  // type={}
                                  style={inputStyle}
                                  onFocus={async (e) => {}}
                                  onBlur={async (e) => {}}
                                  onChange={async (e) => {
                                    let number = e.target.value;
                                    setAddrNumberProfile(number);
                                    setProfileLocal({
                                      ...profileLocal,
                                      address: {
                                        ...profileLocal?.address,
                                        number,
                                      },
                                    });
                                  }}
                                />
                              ),
                            },
                          ],
                        },
                        //Complemento
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "30%",
                            gap: 4,
                            padding: 4,
                            flexDirection: "column",
                          },
                          children: [
                            //text
                            {
                              component: "text",
                              text: "Complemento",
                              style: labelStyle,
                            },
                            //inputComplement
                            {
                              render: (
                                <InputMask
                                  value={addrComplementProfile}
                                  // type={}
                                  style={inputStyle}
                                  onFocus={async (e) => {}}
                                  onBlur={async (e) => {}}
                                  onChange={async (e) => {
                                    let complement = e.target.value;
                                    setAddrComplementProfile(complement);
                                    setProfileLocal({
                                      ...profileLocal,
                                      address: {
                                        ...profileLocal?.address,
                                        complement,
                                      },
                                    });
                                  }}
                                />
                              ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                //Foto de perfil
                {
                  component: "container",
                  style: {
                    display: "flex",
                    marginTop: 12,
                    width: "100%",
                    flexDirection: "column",
                    gap: 12,
                  },
                  children: [
                    //label
                    {
                      component: "text",
                      text: "Logotipo do parceiro",
                      style: {
                        width: "100%",
                        textAlign: "flex-start",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        color: "blue",
                      },
                    },
                    // upload
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        padding: 12,
                        width: "100%",
                        marginTop: 4,
                        borderRadius: 8,
                        borderWidth: 0.1,
                        borderStyle: "solid",
                        borderColor: "rgba(100,100,100, 0.2)",
                      },
                      children: [
                        {
                          render: (
                            <div
                              // {...getRootProps()}
                              style={{
                                width: "100%",
                                height: 42,
                                background: "rgba(219, 234, 254, 0.8)",
                                borderRadius: 10,
                                borderWidth: 2,
                                borderStyle: "dashed",
                                borderColor: "blue",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                id="upload-file"
                                type="file"
                                style={{ display: "flex" }}
                                onChange={async (e) => {
                                  const file = e.target.files[0];
                                  if (file && file.type.startsWith("image/")) {
                                    const reader = new FileReader();
                                    reader.onload = function (event) {
                                      // urlDa imagem
                                      const company_icon = event.target.result;
                                      // Atualizar o profile
                                      setProfileLocal({
                                        ...profileLocal,
                                        company_icon,
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }}
                              />
                              <p
                                style={{
                                  color: "blue",
                                  fontSize: "1vw",
                                  fontFamily: "Poppins",
                                }}
                              ></p>
                            </div>
                          ),
                        },
                      ],
                    },
                  ],
                },
                //button save
                {
                  component: "container",
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "8vh",
                  },
                  children: [
                    {
                      component: "button",
                      text: "Salvar",
                      style: {
                        backgroundColor: "blue",
                        alignItems: "center",
                        width: "24%",
                        height: "64%",
                        color: "white",
                        borderRadius: 6,
                        border: "none",
                        fontSize: 14,
                        cursor: "Pointer",
                        fontFamily: "Poppins",
                      },
                      onClick: async () => {
                        setLoadingSave(true);
                        const update = await api.utils.update({
                          _id: profileLocal._id,
                          type: "provider",
                          nickname: profileLocal.nickname,
                          address: profileLocal.address,
                          whatsapp: profileLocal.whatsapp,
                          company_icon: profileLocal.company_icon,
                          transfer_deadline: profileLocal.transfer_deadline,
                        });
                        if (update?.data?.data?.status === true) {
                          setLoadingSave(false);
                          //atualiza o user
                          Set({
                            ...Data,
                            user: update.data.data.info,
                          });
                          PopupShow({
                            description: "",
                            style: {
                              box: {
                                width: "auto",
                                alignItems: "center",
                              },
                              close: {
                                display: "none",
                              },
                            },
                            item: [
                              {
                                component: "container",
                                style: {
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  width: "100%",
                                  gap: 12,
                                },
                                children: [
                                  {
                                    component: "text",
                                    text: "Perfil salvo com sucesso!",
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: 18,
                                    },
                                  },
                                  {
                                    component: "text",
                                    text: "Fechar",
                                    style: {
                                      display: "flex",
                                      width: "100%",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "row",
                                      gap: 8,
                                      marginTop: 12,
                                      backgroundColor: "#E4EEFF",
                                      height: 42,
                                      borderRadius: 6,
                                      border: "none",
                                      color: "#0864F1",
                                      cursor: "pointer",
                                    },
                                    onClick: () => {
                                      setPopUp(false);
                                      PopupClose();
                                    },
                                  },
                                ],
                              },
                            ],
                            // buttons: [],
                          });
                        } else {
                          setLoadingSave(false);
                          PopupShow({
                            description: "",
                            style: {
                              box: {
                                width: "auto",
                                alignItems: "center",
                              },
                              close: {
                                display: "none",
                              },
                            },
                            item: [
                              {
                                component: "container",
                                style: {
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  width: "100%",
                                  gap: 12,
                                },
                                children: [
                                  {
                                    component: "text",
                                    text: "Erro ao editar perfil!",
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: 18,
                                    },
                                  },
                                  {
                                    component: "text",
                                    text: "Fechar",
                                    style: {
                                      display: "flex",
                                      width: "100%",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "row",
                                      gap: 8,
                                      marginTop: 12,
                                      backgroundColor: "#FFB7B7",
                                      height: 42,
                                      borderRadius: 6,
                                      border: "none",
                                      color: "#DC2626",
                                    },
                                    onClick: () => {
                                      setPopUp(false);
                                      PopupClose();
                                    },
                                  },
                                ],
                              },
                            ],
                            buttons: [],
                          });
                        }
                      },
                    },
                  ],
                },
              ],
            })}
          </Fade>
        ),
      },
    ],
  };
};
