import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//api
import api from "service";

//components/web
import Button from "components/web/button";
import Icon from "components/web/icon";

//images
import People from "assets/images/menu/icon-people.svg";
import CloseDetails from "assets/images/menu/goRight.svg";
import ServiceAdd from "assets/images/menu/serviceAdd.svg";

//pages
import SugestHour from "./sugestHour";

import Theme from "theme";

export default (
  setSugestHourShow,
  details,
  setDetails,
  order,
  setOrder,
  setServiceAddShow
) => {
  const { PopupShow, PopupClose } = ShowPopup();
  const { PageInfo, GoPage, setUpdate } = ShowPage();

  const [imageService, setImageService] = useState("");
  const [nameService, setNameService] = useState("");
  const [nameServiceAdd, setNameServiceAdd] = useState("");
  const [valueServiceAdd, setValueServiceAdd] = useState("");

  useEffect(() => {
    if (order && Object.keys(order).length > 0) {
      setImageService(order?.services[0]?.service?.category?.image);
      setNameService(order?.services[0]?.service?.sub_category?.name);
      setNameServiceAdd(order?.services[1]?.description);
      setValueServiceAdd(order?.services[1]?.value);
    }
  }, [order]);

  return {
    component: "container",
    style: {
      display: details === true ? "flex" : "none",
      width: "100vw",
      minHeight: "100vh",
      maxHeight: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      justifyContent: "flex-end",
      flexDirection: "column",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      alignItems: "flex-end",
      zIndex: 99,
    },
    children: [
      {
        render: (
          <Fade rigth>
            {Render({
              component: "container",
              style: {
                display: "flex",
                minWidth: 384,
                minHeight: "100vh",
                maxHeight: "100vh",
                alignItems: "flex-start",
                flexDirection: "column",
                backgroundColor: "white",
                overflowY: "auto",
                overfloX: "hidden",
                padding: 8,
                gap: 12,
                paddingBottom: 24,
              },
              children: [
                Icon({
                  size: 24,
                  src: CloseDetails,
                  style: {
                    iconBox: {
                      width: "auto",
                      height: "auto",
                      justifyContent: "flex-start",
                      transform: "rotate(180deg)",
                    },
                  },
                  onPress: () => {
                    setDetails(false);
                  },
                }),
                //titlePedido
                {
                  component: "text",
                  text: "Pedido " + "#" + order?.id,
                  style: {
                    fontFamily: "Poppins",
                    fontSize: 24,
                  },
                },
                //container pet
                {
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "100%",
                    height: "18vh",
                    minHeight: 100,
                    gap: 8,
                  },
                  children: [
                    //title content
                    {
                      component: "text",
                      text: "Dados do pet:",
                      style: {
                        fontSize: 14,
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        color: "#0864F1",
                      },
                    },
                    //content
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 12,
                        width: "100%",
                        height: "100%",
                        gap: 8,
                        backgroundColor: "#f0f0f0",
                        borderRadius: 4,
                      },
                      children: [
                        //image
                        {
                          component: "image",
                          src: order?.pet?.picture_thumb,
                          style: {
                            borderRadius: "50%",
                            minWidth: 92,
                            maxWidth: 92,
                            minHeight: 92,
                            maxHeight: 92,
                            cursor: "pointer",
                          },
                          onClick: async () => {
                            const { data } = await api.people.pet({
                              _id: order?.pet?._id,
                            });
                            PopupShow({
                              description: "",
                              style: {
                                box: {
                                  width: "auto",
                                  alignItems: "center",
                                },
                                close: {
                                  display: "none",
                                },
                              },
                              item: [
                                {
                                  component: "container",
                                  style: {
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    width: "100%",
                                  },
                                  children: [
                                    {
                                      component: "image",
                                      src: data?.data?.picture,
                                      style: {
                                        borderRadius: "12%",
                                        width: 432,
                                        height: 432,
                                        aspectRatio: "1/1",
                                      },
                                    },
                                  ],
                                },
                              ],
                              buttons: [
                                {
                                  component: "container",
                                  style: {
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    gap: 8,
                                    marginTop: 12,
                                  },
                                  children: [
                                    //btn fechar
                                    Button({
                                      label: "Fechar",
                                      style: {
                                        button: {
                                          backgroundColor: "#E4EEFF",
                                          width: "100%",
                                          alignItems: "center",
                                          height: 42,
                                          borderRadius: 6,
                                          border: "none",
                                        },
                                        label: {
                                          fontFamily:
                                            Theme.fontFamilies.primary,
                                          fontSize: 18,
                                          color: "#0864F1",
                                        },
                                      },
                                      onPress: async () => {
                                        PopupClose();
                                      },
                                    }),
                                  ],
                                },
                              ],
                            });
                          },
                        },
                        //content text
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 8,
                            gap: 4,
                          },
                          children: [
                            //name pet
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Nome: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: order?.pet?.name,
                                  style: {
                                    fontSize: 14,

                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                            //raça
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Raça: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: order?.pet?.breed?.name,
                                  style: {
                                    fontSize: 14,

                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                            //porte
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Porte: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: order?.pet?.size?.initials,
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                            //temperamento
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Temperamento: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: "Dócil",
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                //container cliente
                {
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "100%",
                    height: "18vh",
                    minHeight: 100,
                    gap: 8,
                  },
                  children: [
                    //title content
                    {
                      component: "text",
                      text: "Dados do cliente:",
                      style: {
                        fontSize: 14,
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        color: "#0864F1",
                      },
                    },
                    //content
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 12,
                        width: "100%",
                        height: "100%",
                        gap: 8,
                        backgroundColor: "#f0f0f0",
                        borderRadius: 4,
                      },
                      children: [
                        //image
                        {
                          component: "image",
                          src: People,
                          style: {
                            borderRadius: "50%",
                            border: "1px solid gray",
                            minWidth: 92,
                            maxWidth: 92,
                            minHeight: 92,
                            maxHeight: 92,
                          },
                        },
                        //content text
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 8,
                            gap: 4,
                          },
                          children: [
                            //name pet
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Nome: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: order?.people?.name,
                                  style: {
                                    fontSize: 14,

                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                            //celular
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Celular: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                    marginRight: 4,
                                  },
                                },
                                {
                                  component: "text",
                                  text:
                                    order?.people?.contacts[0]?.phone
                                      .area_code + "-",
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: order?.people?.contacts[0]?.phone
                                    .number,
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                            //endereço
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Endereço: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text:
                                    order?.people?.address?.find(
                                      (addr) => addr.default
                                    )?.street || "Endereço não encontrado",
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                //container service
                {
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "100%",
                    height: "auto",
                    minHeight: 100,
                    gap: 8,
                  },
                  children: [
                    //title content
                    {
                      component: "text",
                      text: "Dados do serviço:",
                      style: {
                        fontSize: 14,
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        color: "#0864F1",
                      },
                    },
                    //content service
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 12,
                        width: "100%",
                        height: "100%",
                        gap: 8,
                        backgroundColor: "#f0f0f0",
                        borderRadius: 4,
                      },
                      children: [
                        //image
                        {
                          component: "image",
                          src: imageService || People,
                          style: {
                            borderRadius: "50%",
                            minWidth: 92,
                            maxWidth: 92,
                            minHeight: 92,
                            maxHeight: 92,
                          },
                        },
                        //content text
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 8,
                            gap: 4,
                          },
                          children: [
                            //tipo do serviço
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Tipo: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: nameService || "ban",
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                            //horário
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Horário: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: order?.service_date,
                                  style: {
                                    fontSize: 14,

                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                            //valor
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Valor: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text:
                                    "R$ " +
                                    (
                                      (order?.delivery_service_value || 0) +
                                      (order?.service_value || 0)
                                    ).toLocaleString("pt-BR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }),
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                            //leva e traz
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Leva e traz: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text:
                                    order?.delivery_service === true
                                      ? "Sim"
                                      : "Não",
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },

                    //content serviceAdd
                    order?.services?.length > 1 && {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 12,
                        width: "100%",
                        height: "100%",
                        gap: 8,
                        backgroundColor: "#f0f0f0",
                        borderRadius: 4,
                      },
                      children: [
                        //image
                        {
                          component: "image",
                          src: ServiceAdd,
                          style: {
                            borderRadius: "50%",
                            minWidth: 72,
                            maxWidth: 72,
                            minHeight: 72,
                            maxHeight: 72,
                          },
                        },
                        //content text
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 8,
                            gap: 4,
                          },
                          children: [
                            //tipo do serviço
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Tipo: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: "Serviço adicional",
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                            //Descriçao
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Descrição: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: nameServiceAdd,
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                            //valor
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                height: "auto",
                                flexDirection: "row",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Valor: ",
                                  style: {
                                    fontSize: 14,
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: "R$ " + valueServiceAdd,
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                //container button
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    height: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 12,
                    flexDirection: "column",
                  },
                  children: [
                    //btn confirm, cancel, sugestHour
                    order?.status?.code === 998 && {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        height: "auto",
                        flexDirection: "column",
                        gap: 12,
                      },
                      children: [
                        //cancelar order
                        Button({
                          label: "Cancelar pedido",
                          style: {
                            button: {
                              width: "100%",
                              alignItems: "center",
                              border: "none",
                              color: "#FE5B50",
                              height: 44,
                              borderRadius: 12,
                              cursor: "pointer",
                              backgroundColor: "#FFD5D3",
                            },
                            label: {
                              fontFamily: Theme.fontFamilies.primary,
                              fontSize: 18,
                            },
                          },
                          onPress: async () => {
                            PopupShow({
                              title: "Deseja cancelar " + order.id + " ?",
                              cancelText: "Não",
                              confirmText: "Sim",
                              onConfirm: async () => {
                                const result = await api.order.cancel({
                                  order: order._id,
                                });
                                setUpdate("orders" + new Date());
                                GoPage("orders");
                                setDetails(false);
                              },
                              onCancel: async () => {
                                setUpdate("orders" + new Date());
                              },
                              item: [],
                            });
                            setUpdate("orders" + new Date());
                            GoPage("orders");
                          },
                        }),
                        //sugestHour
                        Button({
                          label: "Sugerir horário",
                          style: {
                            button: {
                              alignItems: "center",
                              border: "none",
                              color: "#0864F1",
                              width: "100%",
                              height: 44,
                              borderRadius: 12,
                              cursor: "pointer",
                              backgroundColor: "#E4EEFF",
                            },
                            label: {
                              fontFamily: Theme.fontFamilies.primary,
                              fontSize: 18,
                            },
                          },
                          onPress: async () => {
                            setSugestHourShow(true);
                            setOrder(order);
                          },
                        }),
                        //confirm order
                        Button({
                          label: "Confirmar pedido",
                          style: {
                            button: {
                              alignItems: "center",
                              border: "none",
                              color: "white",
                              width: "100%",
                              height: 44,
                              borderRadius: 12,
                              cursor: "pointer",
                              backgroundColor: "#0864F1",
                            },
                            label: {
                              fontFamily: Theme.fontFamilies.primary,
                              fontSize: 18,
                            },
                          },
                          onPress: async () => {
                            PopupShow({
                              title: "Deseja confirmar " + order.id + " ?",
                              cancelText: "Não",
                              confirmText: "Sim",
                              onConfirm: async () => {
                                const result = await api.order.confirm({
                                  order: order._id,
                                });
                                setUpdate("orders" + new Date());
                                GoPage("orders");
                                setDetails(false);
                              },
                              onCancel: async () => {
                                setUpdate("orders" + new Date());
                              },
                              item: [],
                            });
                            setUpdate("orders" + new Date());
                            GoPage("orders");
                          },
                        }),
                      ],
                    },

                    //btn o pet chegou + btn serviço adicional
                    order?.status?.code === 4 && {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        height: "auto",
                        flexDirection: "column",
                        gap: 12,
                      },
                      children: [
                        //service adicional
                        Button({
                          label: "Serviço adicional",
                          style: {
                            button: {
                              alignItems: "center",
                              border: "none",
                              color: "#0864F1",
                              width: "100%",
                              height: 44,
                              borderRadius: 12,
                              cursor: "pointer",
                              backgroundColor: "#E4EEFF",
                            },
                            label: {
                              fontFamily: Theme.fontFamilies.primary,
                              fontSize: 18,
                            },
                          },
                          onPress: async () => {
                            setServiceAddShow(true);
                            setOrder(order);
                          },
                        }),
                        //o pet chegou
                        Button({
                          label: "O pet chegou",
                          style: {
                            button: {
                              alignItems: "center",
                              border: "none",
                              color: "white",
                              width: "100%",
                              height: 44,
                              borderRadius: 12,
                              cursor: "pointer",
                              backgroundColor: "#0864F1",
                            },
                            label: {
                              fontFamily: Theme.fontFamilies.primary,
                              fontSize: 18,
                            },
                          },
                          onPress: async () => {
                            await api.order.petDelivered({
                              order: order?._id,
                            });
                            setUpdate("orders" + new Date());
                            GoPage("orders");
                            setDetails(false);
                          },
                        }),
                      ],
                    },
                    order?.status?.code === 5 && {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        height: "auto",
                        flexDirection: "column",
                        gap: 12,
                      },
                      children: [
                        //service adicional
                        Button({
                          label: "Serviço adicional",
                          style: {
                            button: {
                              alignItems: "center",
                              border: "none",
                              color: "#0864F1",
                              width: "100%",
                              height: 44,
                              borderRadius: 12,
                              cursor: "pointer",
                              backgroundColor: "#E4EEFF",
                            },
                            label: {
                              fontFamily: Theme.fontFamilies.primary,
                              fontSize: 18,
                            },
                          },
                          onPress: async () => {
                            setServiceAddShow(true);
                            setOrder(order);
                          },
                        }),
                        //o pet chegou
                        Button({
                          label: "O pet chegou",
                          style: {
                            button: {
                              alignItems: "center",
                              border: "none",
                              color: "white",
                              width: "100%",
                              height: 44,
                              borderRadius: 12,
                              cursor: "pointer",
                              backgroundColor: "#0864F1",
                            },
                            label: {
                              fontFamily: Theme.fontFamilies.primary,
                              fontSize: 18,
                            },
                          },
                          onPress: async () => {
                            await api.order.petDelivered({
                              order: order?._id,
                            });
                            setUpdate("orders" + new Date());
                            GoPage("orders");
                            setDetails(false);
                          },
                        }),
                      ],
                    },

                    //btn finalizar serviço
                    order?.status?.code === 3 && {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        height: "auto",
                        flexDirection: "column",
                        gap: 12,
                      },
                      children: [
                        //finalizar serviço
                        Button({
                          label: "Finalizar serviço",
                          style: {
                            button: {
                              alignItems: "center",
                              border: "none",
                              color: "white",
                              width: "100%",
                              height: 44,
                              borderRadius: 12,
                              cursor: "pointer",
                              backgroundColor: "#0864F1",
                            },
                            label: {
                              fontFamily: Theme.fontFamilies.primary,
                              fontSize: 18,
                            },
                          },
                          onPress: async () => {
                            PopupShow({
                              title:
                                "Deseja finalizar o serviço " + order?.id + "?",
                              cancelText: "Não",
                              confirmText: "Sim",
                              onConfirm: async () => {
                                await api.order.finalizeService({
                                  order: order?._id,
                                });
                                setUpdate("orders" + new Date());
                                GoPage("orders");
                                setDetails(false);
                              },
                              onCancel: async () => {
                                setUpdate("orders" + new Date());
                              },
                              item: [],
                            });
                          },
                        }),
                      ],
                    },

                    //btn o pet já foi
                    order?.status?.code === 2 && {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        height: "auto",
                        flexDirection: "column",
                        gap: 12,
                      },
                      children: [
                        //o pet já foi
                        Button({
                          label: "O pet já saiu",
                          style: {
                            button: {
                              alignItems: "center",
                              border: "none",
                              color: "white",
                              width: "100%",
                              height: 44,
                              borderRadius: 12,
                              cursor: "pointer",
                              backgroundColor: "#0864F1",
                            },
                            label: {
                              fontFamily: Theme.fontFamilies.primary,
                              fontSize: 18,
                            },
                          },
                          onPress: async () => {
                            await api.order.finalizeOrder({
                              order: order?._id,
                            });
                            setUpdate("orders" + new Date());
                            GoPage("orders");
                            setDetails(false);
                          },
                        }),
                      ],
                    },

                    //text ajuste de horario
                    order?.status?.code === 997 && {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        height: 44,
                        borderRadius: 12,
                        fontFamily: Theme.fontFamilies.primary,
                        fontSize: 18,
                        color: "#B87130",
                        backgroundColor: "#FFD3AC",
                      },
                      children: [
                        {
                          component: "text",
                          text: order?.status.name,
                          style: {
                            width: "100%",
                          },
                        },
                      ],
                    },
                    //text serviço adicional
                    order?.status?.code === 996 && {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        height: 44,
                        borderRadius: 12,
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: Theme.fontFamilies.primary,
                        fontSize: 18,
                        color: "rgba(5, 150, 105, 1)",
                        backgroundColor: "rgba(167, 243, 208, 1)",
                      },
                      children: [
                        {
                          component: "text",
                          text: order?.status.name,
                          style: {
                            textAlign: "center",
                            fontFamily: Theme.fontFamilies.primary,
                            fontSize: 18,
                            color: "rgba(5, 150, 105, 1)",
                          },
                        },
                      ],
                    },
                    //text finalizado
                    order?.status?.code === 1 && {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        height: 44,
                        borderRadius: 12,
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: Theme.fontFamilies.primary,
                        fontSize: 18,
                        color: "rgba(5, 150, 105, 1)",
                        backgroundColor: "rgba(167, 243, 208, 1)",
                      },
                      children: [
                        {
                          component: "text",
                          text: order?.status.name,
                          style: {
                            textAlign: "center",
                            fontFamily: Theme.fontFamilies.primary,
                            fontSize: 18,
                            color: "rgba(5, 150, 105, 1)",
                          },
                        },
                      ],
                    },
                    //text cancelado
                    order?.status?.code === 0 && {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        height: 44,
                        borderRadius: 12,
                        fontFamily: Theme.fontFamilies.primary,
                        fontSize: 18,
                        color: "rgba(229, 46, 46, 1)",
                        backgroundColor: "rgba(229, 46, 46, 0.5)",
                      },
                      children: [
                        {
                          component: "text",
                          text: order?.status.name,
                          style: {
                            width: "100%",
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            })}
          </Fade>
        ),
      },
    ],
  };
};
