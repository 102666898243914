import { useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";
import { Context } from "context/data";

import ContentTopBar from "components/web/ContentTopBar";

import Theme from "theme";

import Description from "components/local/daycare/description";
import Rules from "components/local/daycare/rules";
import Pets from "components/local/daycare/pets";

//images

import IconBanhoEtosa from "assets/images/service_category/banhoEtosa.png";
import IconRegras from "assets/images/service_category/regras.png";
import IconPasseador from "assets/images/service_category/passeador.png";

export default (props) => {
  const { PageInfo, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const [content, setContent] = useState("description");
  const { Data, Set } = Context();

  return {
    component: "container",
    style: {
      display: PageInfo.show === "daycare" ? "flex" : "none",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100%",
      padding: "1%",
      overflow: "auto",
    },
    children: [
      //topBar
      ContentTopBar({
        title: "Daycare",
      }),
      //text
      {
        component: "container",
        style: {
          display: "flex",
          width: "60%",
          paddingLeft: "2%",
          marginTop: 24,
          marginBottom: 24,
          flexDirection: "column",
        },
        children: [
          {
            component: "text",
            text: "Caso o seu estabelecimento trabalhe com serviço de daycare, aqui é onde você vai configurar a sua vitrine de daycare no aplicativo Petin.",
            style: {
              width: "100%",
              fontSize: 16,
              color: "rgba(117, 117, 117, 1)",
              fontFamily: "Poppins",
            },
            // onClick: () => {
            //   console.log("verUser", Data.user);
            // },
          },
        ],
      },
      //filters
      {
        component: "container",
        style: {
          display: "flex",
          flexFlow: "wrap",
          gap: "10px",
          width: "60vw",
          justifyContent: "flex-start",
          paddingLeft: "2%",
          gap: "20px",
        },
        children: [
          {
            component: "container",
            style: {
              width: 233,
              height: 60,
              border:
                content === "description"
                  ? "1px solid #CBD5E1"
                  : "1px solid #ECECEC",
              backgroundColor:
                content === "description" ? "white" : "transparent",
              borderRadius: 4,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              paddingLeft: "0.5rem",
              cursor: "pointer",
            },
            onClick: () => setContent("description"),
            children: [
              {
                component: "image",
                src: IconBanhoEtosa,
                style: {
                  width: "44px",
                  height: "44px",
                },
              },
              {
                component: "text",
                text: "Descrição",
              },
            ],
          },
          {
            component: "container",
            style: {
              width: 233,
              height: 60,
              border:
                content === "rules" ? "1px solid #CBD5E1" : "1px solid #ECECEC",
              backgroundColor: content === "rules" ? "white" : "transparent",
              borderRadius: 4,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              paddingLeft: "0.5rem",
              cursor: "pointer",
            },
            onClick: () => setContent("rules"),
            children: [
              {
                component: "image",
                src: IconRegras,
                style: {
                  width: "44px",
                  height: "44px",
                },
              },
              {
                component: "text",
                text: "Regras",
              },
            ],
          },
          {
            component: "container",
            style: {
              width: 233,
              height: 60,
              border:
                content === "pets" ? "1px solid #CBD5E1" : "1px solid #ECECEC",
              backgroundColor: content === "pets" ? "white" : "transparent",
              borderRadius: 4,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              paddingLeft: "0.5rem",
              cursor: "pointer",
            },
            onClick: () => setContent("pets"),
            children: [
              {
                component: "image",
                src: IconPasseador,
                style: {
                  width: "44px",
                  height: "44px",
                },
              },
              {
                component: "text",
                text: "Pets aprovados",
              },
            ],
          },
        ],
      },
      {
        component: "container",
        style: {
          display: "flex",
          gap: "10px",
          width: "100%",
          justifyContent: "space-between",
          paddingLeft: "1%",
        },
        children: [
          Description({ content, setContent }),
          Rules({ content, setContent }),
          Pets({ content, setContent }),
        ],
      },
    ],
  };
};
