import Theme from "theme";
import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import { ShowPage } from "context/showPage";
import Select from "components/web/dropDown";
import api from "service";
import { Context } from "context/data";

import AddView from "components/web/view/add";
import Button from "components/web/button";

export default ({ content, setContent }) => {
  const { PageInfo, GoPage, GoBack } = ShowPage();
  const { Data, Set } = Context();

  const [daycareData, setDaycareData] = useState(null);
  const [description, setDescription] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [errors, setErrors] = useState("")

  const allHours = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];

  const inputStyle = {
    item: {
      width: 256,
    },
    input: {
      maxWidth: 256,
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: 16,
      backgroundColor: "transparent",
    },
    title: {
      ...Theme.text.input.title,
    },
    label: {
      fontSize: 16,
    },
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: 16,
    },
    button: {
      padding: 12,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 4,
    },
  };

  useEffect(() => {
    const fetchDaycareData = async () => {
      try {
        const { data } = await api.utils.getUser({
          query: {
            code: "",
            type: "provider",
          },
        });

        const daycare = data?.data?.info?.daycare;

        if (daycare) {
          setDescription(data?.data?.info?.daycare.description)
          setDaycareData(daycare);
        }
      } catch (error) {
        console.error("Erro ao buscar os dados do daycare:", error);
      }
    };

    fetchDaycareData();
  }, [content === "description"]);



  const handleSubmit = async () => {
    // Verifica se a descrição está vazia ou ultrapassa 200 caracteres
    if (description.trim().length === 0) {
      setErrors("A descrição não pode estar vazia.");
      return;
    }

    if (description.trim().length > 200) {
      setErrors("A descrição deve ter no máximo 200 caracteres.");
      return;
    }

    setErrors("")

    const dataToSend = {
      description: description,
      requirements: {
        absence_contagious_diseases: daycareData?.requirements?.absence_contagious_diseases,
        castrated_males: daycareData?.requirements?.castrated_males,
        females_cannot_heat: daycareData?.requirements?.females_cannot_heat,
        not_accept_puppies: daycareData?.requirements?.not_accept_puppies,
        stool_exam: daycareData?.requirements?.stool_exam,
        vaccination_card: daycareData?.requirements?.vaccination_card,
      },
      visit_mandatory: daycareData?.visit_mandatory,
      visit_value: daycareData?.visit_value,
    };

    // console.log("Descrição enviada:", dataToSend);
    await api.daycare.provider_daycare(dataToSend);
    setContent("rules")
  };

  const onCancel = () =>{
    setContent("rules")
  }



  return {
    component: "container",
    style: {
      display: content === "description" ? "flex" : "none",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100%",
      overflowX: "auto",
      overflowY: "auto",
      flex: 1,
      zIndex: 1,
    },
    children: [
      AddView({
        direction: "column",
        showTopBar: false,
        form: {
          onSave: handleSubmit,
          onCancel: onCancel,
          func: (data, info, clear, goBack) => {},
          style: {
            main: {
              flexWrap: "nowrap",
              overflowY: "auto",
              overflowX: "hidden",
              width: "40vw",
              heigth: "100%",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              border: "1px solid #CBD5E1",
              borderRadius: 4,
              gap: 12,
              padding: "1%",
              marginBottom: 12,
              marginTop: 0,
            },
            box: {
              1: {},
            },
            btn: {
              save: {},
              style: {
                width: "40vw",
              },
            },
          },
          box: {
            1: {
              style: {
                display: "flex",
                width: "95%",
                heigth: "100%",
                flexDirection: "column",
                gap: 8,
                padding: 8,
              },
              input: [
                //descrição
                {
                  type: "div",
                  style: {
                    display: "flex",
                    width: "100%",
                    heigth: "100%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  },
                  children: [
                    //text
                    {
                      type: "text",
                      text: "Sobre",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 16,
                      },
                    },
                    {
                      type: "text",
                      text: "Digite abaixo um texto de até 200 caracteres sobre o seu estabelecimento. ",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#757575",
                      },
                    },
                    {
                      id: "description",
                      type: "input",
                      errorMessage: errors,
                      value: description,
                      style: {
                        ...inputStyle,
                        input: {
                          width: "30vw",
                          height: "15vh",
                          border: "1px solid #bfbfbf",
                          borderRadius: 4,
                          padding: 4,
                          fontSize: 14,
                          backgroundColor: "transparent",
                        },
                      },
                      set: (value) => {
                        setDescription(value);
                      },
                    },
                  ],
                },
              ],
            },
            // 2:
            // {
            //   style: {
            //     display: "flex",
            //     width: "95%",
            //     flexDirection: "column",
            //     padding: 8,
            //     justifyContent: "space-around",
            //   },
            //   input: [
            //     //Select time
            //     {
            //       type: "div",
            //       style: {
            //         display: "flex",
            //         maxHeigth: 64,
            //         justifyContent: "space-between",
            //         alignItems: "center",
            //         flexDirection: "row",
            //         borderBottom: "1px solid #CBD5E1",
            //         borderTop: "1px solid #CBD5E1",
            //         paddingBottom: 13,
            //         paddingTop: 13,
            //       },
            //       children: [
            //         //text
            //         {
            //           type: "text",
            //           text: "Horários",
            //           style: {
            //             fontFamily: "Poppins",
            //             fontSize: 16,
            //           },
            //         },
            //         {
            //           type: "div",
            //           style: {
            //           display: "flex",
            //           flexDirection: "row",
            //           width: "50%",
            //           height: "auto",
            //           alignItems: "center",
            //           justifyContent: "center",
            //           },
            //           children:[
            //           {
            //           label: "Horário de chegada",
            //           type: "select",
            //           value: arrivalTime,
            //           set: (event) => {setArrivalTime(event.target.value)
            //             console.log("event", event)
            //           },
            //           option: allHours.map((hour) => ({
            //             value: hour,
            //             name: hour,
            //           })),
            //           style: {
            //             ...inputStyle,
            //           },
            //           required: true,
            //         },
            //         {
            //           label: "Horário de saída",
            //           type: "select",
            //           value: departureTime,
            //           onChange: (event) => setDepartureTime(event.target.value),
            //           option: filteredDepartureHours.map((hour) => ({
            //             value: hour,
            //             name: hour,
            //           })),
            //           style: {
            //             ...inputStyle,
            //           },
            //           required: true,
            //         },
            //           ],
            //         },
            //       ],
            //     },
            //     // Tags
            //     {
            //       type: "div",
            //       style: {
            //         display: "flex",
            //         maxHeigth: 64,
            //         justifyContent: "space-between",
            //         alignItems: "center",
            //         flexDirection: "row",
            //         borderBottom: "1px solid #CBD5E1",
            //         paddingBottom: 13,
            //         paddingTop: 13,
            //       },
            //       children: [
            //         //text
            //         {
            //           type: "text",
            //           text: "Tags",
            //           style: {
            //             fontFamily: "Poppins",
            //             fontSize: 16,
            //           },
            //         },
            //         {
            //           type: "div",
            //           style: {
            //           display: "flex",
            //           flexDirection: "row",
            //           width: "50%",
            //           height: "auto",
            //           alignItems: "center",
            //           justifyContent: "center",
            //           },
            //           children:[
            //           {
            //             type: "select",
            //             // option:,
            //             style: {
            //               ...inputStyle,
            //             },
            //             required: true,
            //         },
            //           ],
            //         },
            //       ],
            //     },
            //     //Upload fotos
            //     {
            //       type: "div",
            //       style: {
            //         display: "flex",
            //         maxHeigth: 64,
            //         justifyContent: "space-between",
            //         alignItems: "center",
            //         flexDirection: "row",
            //         borderBottom: "1px solid #CBD5E1",
            //       },
            //       children: [
            //         //text
            //         {
            //           type: "text",
            //           text: "Upload de fotos",
            //           style: {
            //             fontFamily: "Poppins",
            //             fontSize: 16,
            //           },
            //         },
            //         {
            //           type: "div",
            //           style: {
            //           display: "flex",
            //           flexDirection: "row",
            //           width: "50%",
            //           height: "auto",
            //           alignItems: "center",
            //           justifyContent: "center",
            //           },
            //           children:[
            //             {
            //             type: "file",
            //             style: {
            //               ...inputStyle,
            //             },
            //             required: true,
            //           },
            //           ],
            //         },
            //       ],
            //     },
            //   ],
            // },
          },
        },
      }),
    ],
  };
};
