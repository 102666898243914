import { useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

import CategoryList from "components/web/categoryList/schedules";
import ScheduleList from "components/web/scheduleList";

import Button from "components/web/button";
import PopUp from "components/web/popup";

import { Context } from "context/data";

import Theme from "theme";

import Icon from "components/web/icon";

import SaveDisk from "assets/images/buttons/disk.svg";
import Edit from "assets/images/buttons/edit.svg";

export default () => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const { Data, Set } = Context();

  const [interval, setInterval] = useState(1);

  //variavel de edição
  const [isEdit, setIsEdit] = useState(false);

  //Categoria selecionada no topBar
  const [categorySelected, setCategorySelected] = useState({});

  //Copia o shcedule local
  const [schedule, setSchedule] = useState({});

  const [updatedSchedule, setUpdatedSchedule] = useState({});

  //Copia de dias semanais local
  const [scheduleWeek, setScheduleWeek] = useState({});

  const allHours = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];
  const week = [
    { code: 0, name: "Domingo", enabled: false, off_hours: allHours },
    { code: 1, name: "Segunda", enabled: false, off_hours: allHours },
    { code: 2, name: "Terça", enabled: false, off_hours: allHours },
    { code: 3, name: "Quarta", enabled: false, off_hours: allHours },
    { code: 4, name: "Quinta", enabled: false, off_hours: allHours },
    { code: 5, name: "Sexta", enabled: false, off_hours: allHours },
    { code: 6, name: "Sábado", enabled: false, off_hours: allHours },
  ];

  useEffect(async () => {
    let cSchedule = {};
    Data.user?.schedule?.forEach((item) => {
      cSchedule[item.category] = item;
      if (!cSchedule[item.category].week) {
        cSchedule[item.category].week = week;
      }
    });
    setSchedule(cSchedule);
    if (PageInfo.show !== "schedules") setIsEdit(false);
    //pegar da request do servidor e set no data user.
  }, [PageInfo, isEdit]);

  //Lista de Usuários gerais
  return {
    component: "container",
    style: {
      display: PageInfo.show === "schedules" ? "flex" : "none",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100%",
      padding: "1%",
    },
    children: [
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "flex-start",
          flexDirection: "column",
          gap: 24,
          paddingLeft: 24,
          paddingRight: 24,
        },
        children: [
          //component de categorias
          CategoryList({
            edit: isEdit,
            category: schedule,
            onChange: {
              switch: async (itemId, status, item) => {
                let cpSchedule = schedule;
                if (!schedule[itemId]) {
                  cpSchedule[itemId] = {
                    category: itemId,
                    disabled: false,
                    interval,
                    week: week,
                    holiday: {
                      enabled: true,
                      off_hours: [],
                    },
                  };
                } else {
                  cpSchedule[itemId] = {
                    ...cpSchedule[itemId],
                    disabled: !schedule[itemId].disabled,
                  };
                  setSchedule(cpSchedule);
                }
              },
              itemCategory: (item) => {
                setCategorySelected(item);
              },
            },
          }),
          //title + button
          {
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              height: "auto",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            },
            children: [
              //title de horários
              {
                component: "container",
                style: {
                  display: "flex",
                  height: "auto",
                  width: "auto",
                  flexDirection: "column",
                },
                children: [
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                      flexDirection: "row",
                    },
                    children: [
                      {
                        component: "text",
                        text: "Horários - ",
                        style: {
                          display: categorySelected?.name ? "flex" : "none",
                          fontFamily: "Poppins",
                          fontSize: 24,
                        },
                      },
                      //name
                      {
                        component: "text",
                        text:
                          categorySelected?.name || "Selecione uma categoria",
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 24,
                        },
                      },
                    ],
                  },
                ],
              },
              //button editar/salvar
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "auto",
                  height: "auto",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 18,
                },
                children: [
                  //cancelar
                  Button({
                    style: {
                      button: {
                        display: isEdit === true ? "flex" : "none",
                        height: 34,
                        width: 128,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 4,
                        border: "none",
                        backgroundColor: " #E4EEFF",
                      },
                      label: {
                        fontSize: 14,
                        color: Theme.colors.blue.life,
                        fontFamily: "Poppins",
                      },
                    },
                    label: "cancelar",
                    onPress: () => {
                      PopupShow({
                        description: "",
                        style: {
                          box: {
                            width: "22%",
                            alignItems: "center",
                          },
                          close: {
                            display: "none",
                          },
                        },
                        item: [
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              width: "100%",
                              gap: 12,
                            },
                            children: [
                              {
                                component: "text",
                                text: "Sair sem salvar ?",
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: 18,
                                  color: "#0864F1",
                                },
                              },
                              {
                                component: "text",
                                text: "As alterações não salvas serão descartadas quando você sair.",
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: 14,
                                },
                              },
                            ],
                          },
                        ],
                        buttons: [
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row",
                              gap: 8,
                              marginTop: 12,
                            },
                            children: [
                              //btn fechar
                              Button({
                                label: "Sair",
                                style: {
                                  button: {
                                    backgroundColor: "#E4EEFF",
                                    width: "100%",
                                    alignItems: "center",
                                    height: "42%",
                                    borderRadius: 6,
                                    border: "none",
                                  },
                                  label: {
                                    fontFamily: Theme.fontFamilies.primary,
                                    fontSize: 18,
                                    color: "#0864F1",
                                  },
                                },
                                onPress: async () => {
                                  setIsEdit(!isEdit);
                                  PopupClose();
                                  let cSchedule = {};
                                  Data.user?.schedule?.forEach((item) => {
                                    cSchedule[item.category] = item;
                                  });
                                  setSchedule(cSchedule);
                                },
                              }),
                              //btn salvar
                              Button({
                                label: "Salvar",
                                style: {
                                  button: {
                                    backgroundColor: "#0864F1",
                                    width: "100%",
                                    alignItems: "center",
                                    height: "42%",
                                    borderRadius: 6,
                                    border: "none",
                                  },
                                  label: {
                                    fontFamily: Theme.fontFamilies.primary,
                                    fontSize: 18,
                                    color: "white",
                                  },
                                },
                                onPress: () => {
                                  const { data } =
                                    api.provider.categorySchedule(
                                      Object.values(schedule)
                                    );
                                  Set({
                                    ...Data,
                                    user: {
                                      ...Data.user,
                                      schedule: Object.values(schedule),
                                    },
                                  });
                                  setIsEdit(!isEdit);
                                  PopupClose();
                                },
                              }),
                            ],
                          },
                        ],
                      });
                    },
                  }),
                  //salvar
                  Button({
                    label: isEdit === true ? "Salvar" : "Editar",
                    icon: isEdit === true ? SaveDisk : Edit,
                    style: {
                      button: {
                        height: 34,
                        width: 128,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 4,
                        border: "none",
                        backgroundColor: Theme.colors.blue.life,
                      },
                      label: {
                        color: "white",
                      },
                      image: {
                        width: 22,
                        height: 22,
                      },
                    },
                    onPress: () => {
                      if (isEdit === true) {
                        const { data } = api.provider.categorySchedule(
                          Object.values(schedule)
                        );
                        Set({
                          ...Data,
                          user: {
                            ...Data.user,
                            schedule: Object.values(schedule),
                          },
                        });
                        setIsEdit(!isEdit);
                        PopupClose();
                      } else {
                        PopupClose();
                        setIsEdit(!isEdit);
                      }
                    },
                  }),
                ],
              },
            ],
          },
          //content horários
          ScheduleList({
            edit: isEdit,
            idCategory: categorySelected,
            schedule: schedule,
            onChange: {
              switch: (updatedSchedule) => {
                setSchedule((prevSchedule) => ({
                  ...prevSchedule,
                  ...updatedSchedule,
                }));
              },
              select: (updatedSchedule) => {
                setSchedule((prevSchedule) => ({
                  ...prevSchedule,
                  ...updatedSchedule,
                }));
              },
            },
          }),
        ],
      },
    ],
  };
};
