import { Render } from "@8_dev/sjson-web";
import Theme from "theme";
import api from "service";

import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import PopUp from "components/web/popup";
import { Context } from "context/data";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

import Logo from "assets/images/main-logo1.png";
import Bcg from "assets/images/bcg.jpg";
import Input from "components/web/input";
import Loading from "components/web/loading";

import Login from "pages/access";
import Intro from "pages/intro";

const Page = () => {
  const { Data, Set } = Context();
  const { PageInfo, GoPage, GoInitPage } = ShowPage();

  useEffect(() => {
    const token = window.location.search.split("?token=")[1];
    if (token) {
      if (token?.length !== 0) {
        GoPage("ResetPassword", token);
      } else {
        // Navigation.GoPage("Login");
      }
    }
    window.addEventListener("load", function () {
      setTimeout(function () {
        window.scrollTo(0, 1);
      }, 0);
    });
  }, []);

  return Render({
    component: "container",
    style: {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vh",
      minWidth: "100vw",
      minHeight: "100vh",
      position: "absolute",
      overflow: "hidden",
      backgroundColor: Theme.colors.gray.light,
    },
    children: [
      // loadingSave && Loading(),
      PopUp(),
      Login(),
      {
        render: (
          <div
            style={{
              position: "absolute",
              display: Data.user?.status ? "flex" : "none",
            }}
          >
            <Fade>
              {Render({
                component: "container",
                style: {
                  width: "100vw",
                  height: "100vh",
                  position: "absolute",
                  // backgroundColor: Theme.colors.black,
                },
                children: [Intro()],
              })}
            </Fade>
          </div>
        ),
      },
    ],
  });
};

export default Page;
