import { useEffect, useState } from "react";
import api from "service";
import { ShowPage } from "context/showPage";
import AddView from "components/web/view/add";
import { ShowPopup } from "context/popup";

import Button from "components/web/button";

import Theme from "theme";

import Arrow from "assets/images/menu/arrowBack.svg";

import Loading from "../../../components/web/loading";

export default (props) => {
  const { PageInfo, GoPage, GoBack } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  //animalCategory
  const [animalsType, setAnimalsType] = useState([]);
  const [animalType, setAnimalType] = useState({});

  //categoria
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  //subCategory
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [service, setService] = useState({});

  const [serviceValue, setServiceValue] = useState(0);

  const [loadingView, setLoadingView] = useState(false);

  const inputStyle = {
    maxWidth: 256,
    minWidth: 256,
    item: {
      width: 256,
    },
    input: {
      maxWidth: 256,
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: 16,
      backgroundColor: "transparent",
    },
    title: {
      ...Theme.text.input.title,
    },
    label: {
      fontSize: 16,
    },
    select: {
      border: "1px solid #bfbfbf",
      maxWidth: 256,
      minWidth: 256,
      borderRadius: 4,
      padding: 4,
      fontSize: 16,
    },
    button: {
      padding: 12,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 4,
    },
  };

  useEffect(async () => {
    if (PageInfo.show === "service-edit") {
      setLoadingView(true);
      //category
      let resAnimalType = await api.utils.animal_category();
      setAnimalsType(resAnimalType?.data?.data);

      let { data } = await api.utils.service_category();

      let listCategory = [];
      data?.data?.map((cat) => {
        listCategory.push({
          _id: cat._id,
          name: cat.name,
        });
      });
      setCategory(listCategory);

      //subCategory
      let categoryList = await api.provider.getServicesOptionsSubcategory(
        PageInfo?.param?.info?.category?._id
      );
      let listSubCategory = [];
      categoryList?.data?.data?.map((scat) => {
        listSubCategory.push({
          _id: scat._id,
          name: scat.name,
        });
      });
      setSubCategory(listSubCategory);
      setAnimalType(PageInfo?.param?.info?.animal_category);
      setService(PageInfo?.param?.info);
      setServiceValue(PageInfo?.param?.info?.value);
      setLoadingView(false);
    }
  }, [PageInfo]);

  const handleSubmit = async () => {
    const formattedData = {
      _id: service?._id,
      animal_category: service?.animal_category?._id,
      category: service?.category?._id,
      sub_category: service?.sub_category?._id,
      description: service?.description,
      value: parseFloat(serviceValue),
    };

    if (
      service?.sub_category?._id !== undefined &&
      service?.sub_category?._id !== "none" &&
      service?.category?._id !== undefined &&
      service?.category?._id !== "none"
    ) {
      if (parseFloat(serviceValue) !== 0) {
        try {
          const response = await api.provider.serviceEdit(formattedData);
          GoBack();
        } catch (error) {
          console.error("Erro ao enviar dados:", error.response?.data || error);
        }
      } else {
        PopupShow({
          description: "",
          style: {
            box: {
              width: "auto",
              alignItems: "center",
            },
            close: {
              display: "none",
            },
          },
          item: [
            {
              component: "container",
              style: {
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                gap: 12,
              },
              children: [
                {
                  component: "text",
                  text: "Adicone o valor do serviço!",
                  style: {
                    fontFamily: "Poppins",
                    fontSize: 18,
                  },
                },
              ],
            },
          ],
          buttons: [
            {
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                gap: 8,
                marginTop: 12,
              },
              children: [
                //btn fechar
                Button({
                  label: "Fechar",
                  style: {
                    button: {
                      backgroundColor: "#FFB7B7",
                      width: "100%",
                      alignItems: "center",
                      height: 42,
                      borderRadius: 6,
                      border: "none",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                      color: "#DC2626",
                    },
                  },
                  onPress: async () => {
                    PopupClose();
                  },
                }),
              ],
            },
          ],
        });
      }
    } else {
      PopupShow({
        description: "",
        style: {
          box: {
            width: "auto",
            alignItems: "center",
          },
          close: {
            display: "none",
          },
        },
        item: [
          {
            component: "container",
            style: {
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              gap: 12,
            },
            children: [
              {
                component: "text",
                text: "Selecione a categoria e serviço!",
                style: {
                  fontFamily: "Poppins",
                  fontSize: 18,
                },
              },
            ],
          },
        ],
        buttons: [
          {
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              gap: 8,
              marginTop: 12,
            },
            children: [
              //btn fechar
              Button({
                label: "Fechar",
                style: {
                  button: {
                    backgroundColor: "#FFB7B7",
                    width: "100%",
                    alignItems: "center",
                    height: 42,
                    borderRadius: 6,
                    border: "none",
                  },
                  label: {
                    fontFamily: Theme.fontFamilies.primary,
                    fontSize: 18,
                    color: "#DC2626",
                  },
                },
                onPress: async () => {
                  PopupClose();
                },
              }),
            ],
          },
        ],
      });
    }
  };

  //ServiceAdd
  return {
    component: "container",
    style: {
      display: PageInfo.show === "service-edit" ? "flex" : "none",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100vh",
      overflowX: "auto",
      overflowY: "auto",
      flex: 1,
      zIndex: 1,
    },
    children: [
      loadingView && Loading(),
      AddView({
        direction: "column",
        title: "Edição de serviço",
        srcTopBar: Arrow,
        form: {
          onSave: handleSubmit,
          func: (data, info, clear, goBack) => {},
          style: {
            main: {
              flexWrap: "wrap",
              overflowY: "auto",
              overflowX: "auto",
              width: "50%",
              heigth: "100%",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              border: "1px solid #CBD5E1",
              borderRadius: 4,
              gap: 12,
              padding: "1%",
              marginBottom: 12,
            },
            box: {
              1: {},
            },
            btn: {
              cancel: {},
              save: {},
              style: {
                width: "50%",
              },
            },
          },
          box: {
            1: {
              style: {
                display: "flex",
                minWidth: "100%",
                flexDirection: "column",
                gap: 22,
                padding: 8,
              },
              input: [
                //Button animal categoory
                {
                  type: "div",
                  style: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: 12,
                    borderBottom: "1px solid #CBD5E1",
                  },
                  children: [
                    //text
                    {
                      type: "text",
                      text: "Esse serviço é para qual espécie ?",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 16,
                      },
                    },
                    //button
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        padding: 8,
                        gap: 8,
                        width: "auto",
                      },
                      children: [
                        ...animalsType?.map((pet) => ({
                          type: "div",
                          style: {
                            display: "flex",
                            maxHeigth: 64,
                            maxWidth: 300,
                            borderRadius: 4,
                            flexDirection: "row",
                            border:
                              pet?._id == animalType?._id
                                ? "1px solid #2EC4B6"
                                : "1px solid #D2D2D2",
                            background:
                              pet?._id == animalType?._id
                                ? "rgba(46, 196, 182, 0.3)"
                                : "#F7F7FC",
                          },
                          children: [
                            {
                              type: "image",
                              src: pet?.image,
                              size: 32,
                              style: {
                                iconBox: {
                                  width: 32,
                                  heigth: 32,
                                  cursor: "normal",
                                },
                              },
                            },
                            {
                              type: "button",
                              label: pet?.name,
                              style: inputStyle,
                            },
                          ],
                        })),
                      ],
                    },
                  ],
                },
                //Select category
                {
                  type: "div",
                  style: {
                    display: "flex",
                    maxHeigth: 64,
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: 12,
                    borderBottom: "1px solid #CBD5E1",
                  },
                  children: [
                    //text
                    {
                      type: "text",
                      text: "Selecione a categoria do serviço",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 16,
                      },
                    },
                    //select
                    {
                      type: "select",
                      style: inputStyle,
                      option: category,
                      update: category,
                      value: service?.category?._id,
                      func: async (cat) => {
                        setService({ ...service, category: cat });
                        let categoryList =
                          await api.provider.getServicesOptionsSubcategory(
                            cat._id
                          );
                        let listSubCategory = [];
                        categoryList.data.data.map((scat) => {
                          listSubCategory.push({
                            _id: scat._id,
                            name: scat.name,
                            // value: scat,
                          });
                        });
                        setSubCategory(listSubCategory);
                      },
                    },
                  ],
                },
                //Select service category
                {
                  type: "div",
                  style: {
                    display: "flex",
                    maxHeigth: 64,
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: 12,
                    borderBottom: "1px solid #CBD5E1",
                  },
                  children: [
                    //text
                    {
                      type: "text",
                      text: "Selecione o nome do serviço",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 16,
                      },
                    },
                    //button
                    {
                      type: "select",
                      style: inputStyle,
                      option: subCategory,
                      update: subCategory,
                      value: service?.sub_category?._id,
                      func: async (sub_cat) => {
                        setService({ ...service, sub_category: sub_cat });
                      },
                    },
                  ],
                },
              ],
            },
            2: {
              style: {
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: 8,
                padding: 8,
              },
              input: [
                // value
                {
                  type: "div",
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  },
                  children: [
                    //text
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        width: "auto",
                        flexDirection: "row",
                        gap: 4,
                      },
                      children: [
                        //text
                        {
                          type: "text",
                          text: "Valor - " + service?.size?.name || "",
                          style: {
                            display: "flex",
                            fontFamily: "Poppins",
                            fontSize: 16,
                          },
                        },
                        //text
                        {
                          type: "text",
                          text: "(" + service?.size?.initials + ")" || "",
                          style: {
                            display:
                              service?.size?.initials === "Todos"
                                ? "none"
                                : "flex",
                            fontFamily: "Poppins",
                            fontSize: 16,
                          },
                        },
                      ],
                    },
                    //input
                    {
                      type: "inputCurrency",
                      placeHolder: "0,00",
                      style: {
                        ...inputStyle,
                        input: {
                          backgroundColor:
                            serviceValue === 0 ? "#d3d3d3" : "transparent",
                          border: "none",
                        },
                        coin: { display: "none" },
                        box: {
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      },
                      value: serviceValue,
                      set: (e, value) => {
                        setServiceValue(value);
                        // setService({ ...service, value });
                      },
                    },
                  ],
                },
              ],
            },
            3: {
              style: {
                display: "flex",
                width: "100%",
                heigth: "100%",
                flexDirection: "column",
                gap: 8,
                padding: 8,
              },
              input: [
                //descrição
                {
                  type: "div",
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    heigth: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  },
                  children: [
                    //text
                    {
                      type: "text",
                      text: "Descrição",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 16,
                      },
                    },
                    //button
                    {
                      id: "description",
                      type: "input",
                      style: {
                        ...inputStyle,
                        input: {
                          maxWidth: 456,
                          border: "1px solid #bfbfbf",
                          borderRadius: 4,
                          padding: 4,
                          fontSize: "2vh",
                          backgroundColor: "transparent",
                        },
                      },
                      set: (e) => {
                        setService({ ...service, description: e });
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
      }),
    ],
  };
};
