import Theme from "theme";

export default (props) => {
  return {
    component: "container",
    style: {
      ...Theme.box.indicator.main,
      ...props.style?.main,
    },
    children: [
      //Text
      {
        component: "text",
        text: props.title,
        style: {
          ...Theme.box.indicator.title,
          ...props.style?.title,
        },
      },
      //Number
      {
        component: "text",
        text: props.value,
        style: {
          ...Theme.box.indicator.value,
          ...props.style?.value,
        },
      },
    ],
  };
};
