import Theme from "theme";
import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import Eye from "assets/images/menu/Eye.svg";
import EyeSlash from "assets/images/menu/EyeSlash.svg";
let state = {};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const testFields = (field) => {
  if (field.type) {
  }

  if (field.min) {
    if (field.value.length < field.min)
      return {
        error:
          "Tamanho minimo não atingido " + field.value.length + " " + field.min,
      };
  }

  return true;
};
export default (props) => {
  // let inptValue =
  // se nao tiver id criar um id e add state
  const thisID = props.id || Object.keys(state).length;
  // console.log("thisID", thisID);
  if (!state[thisID]) state[thisID] = { focus: false };

  let stylePlaceholder = {};
  let placeholderShow = true;
  let styleTitle = {};
  let styleInput = {};

  if (props?.style?.title) {
    styleTitle = props.style.title;
    delete props.style.title;
  }
  if (props?.style?.input) {
    styleInput = props.style.input;
    delete props.style.input;
  }

  const [inptValue, setInptValue] = useState(props.value);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(async () => {
    setInptValue("");

    if (props.setValue) props.setValue("");
    if (props.set) {
      if (props.keyid !== undefined) props.set({ k: props.keyid, v: "" });
      else props.set({ k: props.id, v: "" });
    }
  }, [props.clear]);

  useEffect(async () => {
    if (props.value === undefined) setInptValue("");
    else setInptValue(props.value);
  }, [props.value]);

  let inpt = {
    ...props,
    // value: props?.value?.length >= 0 ? props.value : "heyy",
    // value: "2121",
    focus: false,
    error: false,
  };
  // fazer o input nao criar hook e retorna o value atravez da função set
  // pegar o set e value do contexto da request

  // const [inpt, setInpt] = useState({
  //   value: "",
  //   focus: false,
  //   error: false,
  //   ...props,
  // });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  // const [inputError, setInputError] = useState(false);
  return {
    component: "container",
    style: {
      backgroundColor: "rgba(220,230,250,0)",
      display: "flex",
      maxWidth: "100%",
      overflow: "hidden",
      flexDirection: "column",
      alignItems: "space-between",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: "transparent",
      overflow: "hidden",
      ...inpt.style,
    },
    // onClick: () => { setInpt({...inpt, focus: true}) },
    children: [
      {
        component: "text",
        style: {
          ...Theme.text.input.title,
          display: inpt.title ? "flex" : "none",
          marginTop: 4,
          marginBottom: 4,
          flex: 1,
          width: "100%",
          // fontWeight: state[thisID].focus === true ? "bold" : "normal",
          // color: state[thisID].focus
          //   ? Theme.colors.secondary
          //   : Theme.colors.black,
          backgroundColor: "transparent",

          ...styleTitle,
        },
        text: inpt.title || "",
      },
      // {
      //   component: "container",
      //   style: {
      //     display: "flex",
      //     position: "absolute",
      //     right: 0,
      //     width: 32,
      //     aspectRatio: "1/1",
      //     border: "1px solid black",
      //   },
      // },
      // criar um input mask geral com dados de entrada
      {
        render: (
          <div
            style={{
              display: "flex",
              ...Theme.text.input.title,
              alignItems: "center",
              justifyContent: "flex-start",
              overflow: "hidden",
              backgroundColor: "transparent",
              border: "none",
              outlineStyle: "none",
              // ...styleInput,
            }}
          >
            <InputMask
              value={inptValue}
              mask={inpt.mask || ""}
              type={showPassword ? "text" : inpt.type || ""}
              maxLength={props?.maxLength}
              style={{
                ...Theme.text.input.title,
                minHeight: "100%",
                height: 34,
                outlineStyle: "none",
                backgroundColor: "transparent",
                textAlign: "justify",
                overflowWrap: "break-word",
                wordBreak: "break-word",
                borderRadius: "4px",
                flex: 1,
                // fontSize:
                //   state[thisID].focus == true
                //     ? Theme.fontSizes.focus
                //     : Theme.fontSizes.normal,
                // fontWeight: state[thisID].focus === true ? "bold" : "normal",
                // paddingLeft: 8,
                border: "none",
                // borderBottom:
                //   state[thisID].focus == true
                //     ? "1px solid #000"
                //     : "1px solid #000",
                ...styleInput,
              }}
              onFocus={async (e) => {
                state[thisID].focus = true;
              }}
              onBlur={async (e) => {
                state[thisID].focus = false;
                // inptValue = "";
                // console.log(inptValue);
                // this.refs.inputElement.value = "";
              }}
              onChange={async (text) => {
                // retirar mascara
                let value = inpt.mask
                  ? text.target.value.replace(/\D/g, "")
                  : text.target.value;

                // if (props.check)
                //   console.log(
                //     "check",
                //     props,
                //     testFields({
                //       value: value,
                //       ...props.check,
                //     })
                //   );

                // inpt = { ...inpt, value };
                // setInptValue(value);
                // inptValue = value;
                setInptValue(value);
                // props.value = value;
                if (props.setValue) props.setValue(value);
                if (props.set) {
                  if (props.keyid !== undefined)
                    props.set({ k: props.keyid, v: value });
                  else props.set({ k: props.id, v: value });
                }
              }}
              placeHolder={props.placeHolder}
              disabled={props.disabled || false}
            />
            {inpt.type === "password" && (
              <button
                onClick={handleTogglePassword}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  outline: "none",
                  paddingLeft: 8,
                  position: "absolute",
                  right: "3rem",
                }}
              >
                <img src={showPassword ? Eye : EyeSlash} />
              </button>
            )}

            {/* <div
              style={{
                width: "70%",
                height: "100%",
                ...stylePlaceholder,
                display:
                  state[thisID].focus === true || inpt.value.length === 0
                    ? "flex"
                    : "none",
                // alignItems: "center",
                zIndex: 1,
                // position: "relative",
                // marginTop: "-5.5vh",
                marginLeft: 8,
                // backgroundColor: "green",
                zIndex: 1,
              }}
            >
              {props.placeholder}
            </div> */}
          </div>
        ),
      },
    ],
  };
};
