import { Render } from "@8_dev/sjson-web";
import { useEffect, useState } from "react";
import { Fade } from "react-reveal";

//images
import ProviderIcon from "assets/images/menu/icon-provider.svg";
import BusinessIcon from "assets/images/menu/icon-business.svg";
import UserIcon from "assets/images/menu/icon-people.svg";
import OrderIcon from "assets/images/menu/icon-order.svg";

import { ShowPage } from "context/showPage";
import Theme from "theme";
import api from "service";
import homeBox from "components/web/homeBox";
import IndicatorBox from "components/web/indicatorBox";
import Page from "../../init";

// import { Table } from "components/web/table";

export default (props) => {
  const { GoPage, PageInfo } = ShowPage();

  const [infoHome, setInfoHome] = useState({
    order: {
      count: {
        new: 0,
        waiting: 0,
        finish: 0,
      },
    },
    promotion: {
      category: {
        1: Boolean,
      },
    },
    schedule: {
      week: {
        0: Boolean,
      },
    },
    service: {
      category: {
        1: Boolean,
      },
    },
  });

  useEffect(async () => {
    if (PageInfo.show === "Home") {
      api.home.get().then(({ data }) => {
        if (data?.data) setInfoHome({ ...infoHome, ...data.data });
      });
    }
  }, [PageInfo.show]);

  return {
    render: (
      <div
        style={{
          flex: 1,
          display: PageInfo.show === "Home" ? "flex" : "none",
        }}
      >
        <Fade left>
          {Render({
            component: "container",
            style: {
              width: "100%",
              height: "100%",
            },
            children: [
              {
                component: "container",
                style: {
                  width: "60%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  paddingTop: "10vh",
                  gap: "2.66vh",
                },
                children: [
                  //Pedidos + user
                  {
                    component: "container",
                    style: {
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    },
                    children: [
                      //pedidos
                      homeBox({
                        title: "Pedidos",
                        icon: OrderIcon,
                        style: {
                          main: {
                            width: "22vw",
                            top: 0,
                          },
                          top: {
                            main: {},
                            title: {},
                            count: {},
                          },
                        },
                        count: [
                          {
                            value:
                              (infoHome?.order?.count?.new || "0") +
                              " Novos(s)",
                          },
                          {
                            value:
                              (infoHome?.order?.count?.waiting || "0") +
                              " Em andamento",
                          },
                          {
                            value:
                              (infoHome?.order?.count?.finish || "0") +
                              " Finalizados",
                          },
                        ],
                        action: {
                          seeAll: () => {
                            GoPage("orders");
                          },
                          btn: [],
                        },
                      }),
                    ],
                  },
                ],
              },
            ],
          })}
        </Fade>
      </div>
    ),
  };
};
