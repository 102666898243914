import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import Switch from "components/web/switch";
import Select from "components/web/dropDown";
import Icon from "components/web/icon";

import IconAdd from "assets/images/menu/circle-add.svg";
import IconDelet from "assets/images/menu/botao-fechar.png";

export default (props) => {
  const DaysWeek = [
    { code: 0, name: "Domingo" },
    { code: 1, name: "Segunda" },
    { code: 2, name: "Terça" },
    { code: 3, name: "Quarta" },
    { code: 4, name: "Quinta" },
    { code: 5, name: "Sexta" },
    { code: 6, name: "Sábado" },
  ];

  const allHours = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];

  const [selectedHours, setSelectedHours] = useState({});
  const [schedule, setSchedule] = useState({});
  const [categorySelected, setCategorySelected] = useState({});

  //transforma off_hours em close-open.
  useEffect(() => {
    const newSchedule = props?.schedule || {};
    setSchedule(newSchedule);
    setCategorySelected(props?.idCategory);
    const updatedSelectedHours = {};
    const categoryId = props?.idCategory?._id;

    if (newSchedule[categoryId]) {
      const weekSchedule = newSchedule[categoryId].week;

      DaysWeek.forEach((day) => {
        const dayData = weekSchedule[day?.code] || {};
        const offHours = dayData?.off_hours || [];
        const openHours = allHours.filter((hour) => !offHours.includes(hour));

        const intervals = [];
        let start = openHours[0];

        for (let i = 1; i < openHours.length; i++) {
          const prevHour = parseInt(openHours[i - 1].replace(":", ""), 10);
          const currHour = parseInt(openHours[i].replace(":", ""), 10);
          if (currHour !== prevHour + 100) {
            intervals.push({ open: start, close: openHours[i - 1] });
            start = openHours[i];
          }
        }
        intervals.push({ open: start, close: openHours[openHours.length - 1] });

        updatedSelectedHours[day.code] = intervals;
      });

      console.log("verHours", updatedSelectedHours);

      setSelectedHours(updatedSelectedHours);
    }
  }, [props?.idCategory, props?.edit]);

  //transforma open e close em off_hours
  useEffect(() => {
    if (Object.keys(selectedHours).length > 0) {
      setSchedule((prevSchedule) => {
        const updatedSchedule = JSON.parse(JSON.stringify(prevSchedule));
        const categoryId = categorySelected?._id;

        if (!updatedSchedule[categoryId]) {
          updatedSchedule[categoryId] = { week: {} };
        }

        Object.entries(selectedHours).forEach(([dayCode, intervals]) => {
          const occupiedHours = [];

          intervals.forEach(({ open, close }) => {
            if (open && close) {
              const startIdx = allHours.indexOf(open);
              const endIdx = allHours.indexOf(close);
              if (startIdx !== -1 && endIdx !== -1) {
                occupiedHours.push(...allHours.slice(startIdx, endIdx + 1));
              }
            }
          });

          const closedHours = allHours.filter(
            (hour) => !occupiedHours.includes(hour)
          );

          // Atualiza off_hours para o dia específico
          if (!updatedSchedule[categoryId].week[dayCode]) {
            updatedSchedule[categoryId].week[dayCode] = {};
          }
          updatedSchedule[categoryId].week[dayCode].off_hours = closedHours;
        });

        props?.onChange?.select && props.onChange.select(updatedSchedule);
        return updatedSchedule;
      });
    }
  }, [selectedHours]);

  // Função para alternar o enabled do dia
  const toggleDay = (dayCode) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule = JSON.parse(JSON.stringify(prevSchedule));
      const categoryId = categorySelected?._id;
      if (!updatedSchedule[categoryId]) {
        updatedSchedule[categoryId] = { week: {} };
      }

      updatedSchedule[categoryId].week[dayCode].enabled =
        !updatedSchedule[categoryId].week[dayCode].enabled;

      props?.onChange?.switch && props.onChange.switch(updatedSchedule);
      return updatedSchedule;
    });
  };

  // Filtra as opções de 'open'
  const getFilteredOpenOptions = (dayCode, index) => {
    const prevInterval = selectedHours[dayCode]?.[index - 1];

    if (index === 0) {
      // Para o primeiro intervalo, todas as horas estão disponíveis
      return allHours;
    } else if (prevInterval?.close) {
      // Para os novos intervalos, o 'open' deve ser após o 'close' do intervalo anterior
      const lastClose = prevInterval.close;
      const startIdx = allHours.indexOf(lastClose) + 1;
      return allHours.slice(startIdx);
    }

    return [];
  };

  // Filtra as opções de 'close'
  const getFilteredCloseOptions = (dayCode, index) => {
    const openTime = selectedHours[dayCode]?.[index]?.open;

    if (openTime) {
      const startIdx = allHours.indexOf(openTime) + 1;
      return allHours.slice(startIdx);
    }

    return [];
  };

  // Handle para mudar o selectedHours
  const handleHourChange = (dayCode, type, value, index) => {
    setSelectedHours((prev) => {
      const updatedDayIntervals = [...(prev[dayCode] || [])];

      if (updatedDayIntervals[index]) {
        updatedDayIntervals[index][type] = value;
      } else {
        updatedDayIntervals.push({ [type]: value });
      }
      return {
        ...prev,
        [dayCode]: updatedDayIntervals,
      };
    });
  };

  // Adicionar um novo intervalo de horário
  const addHours = (dayCode) => {
    const updatedSelectedHours = { ...selectedHours };

    if (!updatedSelectedHours[dayCode]) {
      updatedSelectedHours[dayCode] = [];
    }

    // Adiciona um novo intervalo com 'open' e 'close' vazios
    updatedSelectedHours[dayCode].push({ open: "", close: "" });
    setSelectedHours(updatedSelectedHours);
  };

  // Remover horário
  const removeHours = (dayCode, idx) => {
    setSelectedHours((prev) => ({
      ...prev,
      [dayCode]: prev[dayCode]?.filter((_, index) => index !== idx),
    }));
  };

  return {
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      maxHeight: "100vh",
      overflowY: "auto",
      overflowX: "hidden",
    },
    children: [
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          minHeight: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: 24,
          flexDirection: "column",
          ...props?.style?.box,
        },
        children: DaysWeek.map((day, idx) => {
          const dayData =
            schedule[categorySelected?._id]?.week?.[day.code] || {};
          return {
            render: (
              <Fade left>
                {Render({
                  component: "container",
                  style: {
                    display: schedule[categorySelected?._id] ? "flex" : "none",
                    width: "50%",
                    height: "auto",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRadius: 8,
                    flexDirection: "row",
                    borderBottom: "1px solid #ECECEC",
                  },
                  children: [
                    //switch + title
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "50%",
                        height: "100%",
                        alignItems: "center",
                        flexDirection: "row",
                        padding: 8,
                        gap: 4,
                      },
                      children: [
                        Switch({
                          style: {
                            display: props.edit ? "flex" : "none",
                            height: 24,
                          },
                          value: dayData?.enabled || false,
                          checked: dayData?.enabled || false,
                          onChange: () => toggleDay(day.code),
                        }),
                        {
                          component: "text",
                          text: day.name,
                          style: {
                            fontSize: 16,
                            marginTop: 8,
                            color: dayData?.enabled ? "black" : "gray",
                            fontFamily: "Poppins",
                            marginBottom: 8,
                            whiteSpace: "nowrap",
                          },
                          onClick: () => {
                            console.log("verHor", selectedHours);
                          },
                        },
                      ],
                    },
                    // Selects de horário
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "auto",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 8,
                      },
                      children: [
                        //intervalos de horário
                        {
                          component: "container",
                          style: {
                            display: props.edit === true ? "flex" : "none",
                            width: "auto",
                            height: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          },
                          children: selectedHours[day.code]?.map(
                            (hour, idx) => {
                              return {
                                component: "container",
                                style: {
                                  display: "flex",
                                  width: "auto",
                                  height: "auto",
                                  alignItems: "center",
                                  justifyContent: "center",
                                },
                                children: [
                                  // "Abre às"
                                  Select({
                                    title: "Abre às",
                                    disabled: !dayData?.enabled,
                                    options: getFilteredOpenOptions(
                                      day.code,
                                      idx
                                    ),
                                    value: selectedHours[day.code]?.[idx]?.open,
                                    onChange: (e) =>
                                      handleHourChange(
                                        day.code,
                                        "open",
                                        e.value,
                                        idx
                                      ),
                                  }),
                                  // "Fecha às"
                                  Select({
                                    title: "Fecha às",
                                    disabled: !dayData?.enabled,
                                    options: getFilteredCloseOptions(
                                      day.code,
                                      idx
                                    ),
                                    value:
                                      selectedHours[day.code]?.[idx]?.close,
                                    onChange: (e) =>
                                      handleHourChange(
                                        day.code,
                                        "close",
                                        e.value,
                                        idx
                                      ),
                                  }),
                                  //addHours
                                  {
                                    component: "container",
                                    style: {
                                      display:
                                        props.edit === true && idx === 0
                                          ? "flex"
                                          : "none",
                                      width: "100%",
                                      height: "100%",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      paddingTop: 12,
                                      marginLeft: 12,
                                    },
                                    children: [
                                      Icon({
                                        src: IconAdd,
                                        size: 24,
                                        style: {
                                          iconBox: {
                                            display: props.edit
                                              ? "flex"
                                              : "none",
                                            width: 24,
                                            height: 24,
                                          },
                                        },
                                        onPress: () => addHours(day?.code, idx),
                                      }),
                                    ],
                                  },
                                  //deletHours
                                  {
                                    component: "container",
                                    style: {
                                      display:
                                        props.edit === true && idx > 0
                                          ? "flex"
                                          : "none",
                                      width: "100%",
                                      height: "100%",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      paddingTop: 12,
                                      marginLeft: 12,
                                    },
                                    children: [
                                      Icon({
                                        src: IconDelet,
                                        size: 24,
                                        style: {
                                          iconBox: {
                                            display: props.edit
                                              ? "flex"
                                              : "none",
                                            width: 24,
                                            height: 24,
                                          },
                                        },
                                        onPress: () =>
                                          removeHours(day.code, idx),
                                      }),
                                    ],
                                  },
                                ],
                              };
                            }
                          ),
                        },
                        // Texto com o horário de abertura e fechamento
                        {
                          component: "container",
                          style: {
                            display: props.edit ? "none" : "flex",
                            width: "auto",
                            minWidth: 192,
                            height: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor:
                              dayData?.off_hours?.length === 24 ||
                              dayData?.enabled === false ||
                              schedule[categorySelected?._id]?.disabled === true
                                ? "rgba(205, 205, 205, 0.5)"
                                : "#E4EEFF",
                            padding: 8,
                            gap: 4,
                            borderRadius: 4,
                            flexDirection: "column",
                          },
                          children: selectedHours[day.code]?.map(
                            (item, idx) => {
                              console.log(item);
                              return {
                                component: "container",
                                style: {
                                  display: "flex",
                                  width: "100%",
                                  height: "auto",
                                  flexDirection: "row",
                                },
                                children: [
                                  //de: open ate: close
                                  {
                                    component: "container",
                                    style: {
                                      display:
                                        selectedHours[day.code]?.[0].open &&
                                        dayData.enabled === true
                                          ? "flex"
                                          : "none",
                                      flexDirection: "row",
                                      gap: 4,
                                    },
                                    children: [
                                      //De
                                      {
                                        component: "text",
                                        text: "De",
                                        style: {
                                          color: "black",
                                          fontFamily: "Poppins",
                                          whiteSpace: "pre-line",
                                        },
                                      },
                                      //open
                                      {
                                        component: "text",
                                        text: item.open,
                                        style: {
                                          display: "flex",
                                          color: "black",
                                          fontFamily: "Poppins",
                                          whiteSpace: "pre-line",
                                        },
                                      },
                                      //até
                                      {
                                        component: "text",
                                        text: "Até",
                                        style: {
                                          display: "flex",
                                          color: "black",
                                          fontFamily: "Poppins",
                                          whiteSpace: "pre-line",
                                        },
                                      },
                                      //close
                                      {
                                        component: "text",
                                        text: item.close,
                                        style: {
                                          display: "flex",
                                          color: "black",
                                          fontFamily: "Poppins",
                                          whiteSpace: "pre-line",
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    component: "text",
                                    text:
                                      selectedHours[day.code]?.[0].open ===
                                        undefined && dayData.enabled === true
                                        ? "Sem horário configurado"
                                        : "Fechado",
                                    style: {
                                      display:
                                        selectedHours[day.code]?.[0].open ===
                                          undefined || dayData.enabled === false
                                          ? "flex"
                                          : "none",
                                    },
                                  },
                                ],
                              };
                            }
                          ),
                        },
                      ],
                    },
                  ],
                })}
              </Fade>
            ),
          };
        }),
      },
    ],
  };
};
